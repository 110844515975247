.user-menu,
.secondary-menu {
	float: left;
	padding-left: 4px;
	height: 4.25*$base-size;

	.footer & {
		float: none;
		padding-top: 3.4*$base-size;
		padding-left: 0;
		height: auto;


		  @media only screen  and (max-width : $breakpoint-tablet) {
		     padding-top: 9.5*$base-size !important; 
		     padding-left: 4*$base-size;
		  }

		.sub-menu { 
			display: none;
		}
	}

	li {
		display: inline-block;
		position: relative;

		&:hover .sub-menu {
			display:block;

			.footer & {
				display: none;
			}
		}

		
		.footer & {
			display: block;
		}

		&.menu-members {
			background-color: $darkest-green;
		}
		
		a {
			@extend .secondary-menu-links;
			padding-left: 2.5*$base-size;
			padding-right: 2.5*$base-size;
			padding-top: 1.5*$base-size;
			padding-bottom: 1.3*$base-size;
			display: block;
			line-height: 0.8;
			color: white;
			font-size: 1.625*$base-size;
			font-weight: 300;

			.footer & {
				padding-left: 0;
				padding-top: 1*$base-size;
				padding-bottom: 1*$base-size;
				line-height: 1.1;
			}
		}

		&:nth-child(1) a {
			padding-left: 0;
		}

	}

	.sub-menu {
		display: none;
		position: absolute;

	}
}

.user-menu {
	float: right;

	li {

		&:nth-child(1) a {
			padding-left: 2.5*$base-size;
		}
	}
}