$gutter: 3.5rem;
$offset: $gutter/2;

$gutter-w-desktop: 100vw - (#{$offset}* 2);
$container: calc(100vw - (#{$offset}* 2));
$container: 98vw;

$sans-serif-light: "FFMiloWebProLight", arial;
$sans-serif-text: "FFMiloWebProText", arial;
$sans-serif-medium: "FFMiloWebProMed", arial;
$zilla: "Zilla Slab", arial;

$sans-serif: $sans-serif-light;
$base-size: 1rem;
$offset-mobile: 3 * $base-size;

$col-2: calc(16.66667% - 4.08333rem + 7rem);
$col-2-gutter: calc((16.66667% - 4.08333rem + 7rem)+$gutter);
$col-3: calc(25% - 4.375rem + 7rem);
