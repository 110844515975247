
@import "solar";
@import "partials/reset";
@import "partials/breakpoints";
@import "partials/colors";
@import "partials/variables";
@import "partials/typography";

@import "modules/footer";
@import "modules/header";
@import "modules/secondary-menu";
@import "modules/teritary-menu";
@import "modules/primary-menu";
@import "modules/sub-menu";
@import "modules/resistance-management";
@import "modules/big-bird";
@import "modules/breadcrumbs";
@import "modules/buttons";
@import "modules/inputs";
@import "modules/audio-player";

@import "pages/front-page";

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

:root {
  font-size: 8px;
  font-size: calc(8px + 0.25vw);
  
	@media only screen  and (min-width : $breakpoint-tablet) {
		font-size: calc(4px + 0.25vw);
	}
  
	@media only screen  and (min-width : $breakpoint-desktop) {
		font-size: calc(5px + 0.25vw);
  
		@supports (-ms-ime-align:auto) {
		  // font-size: calc(0.4em + 0.25vw) !important;
		}
	}
  
	@media only screen  and (min-width : $breakpoint-desktop-medium) {
		font-size: calc(6px + 0.25vw);
  
		@supports (-ms-ime-align:auto) {
		  // font-size: calc(0.4em + 0.25vw) !important;
		}
	}

}

body {
	margin:0;
	font-family:$sans-serif;
	font-size:$base-size;
	min-height: 100vh;
}

p, li, span, a {
	@extend .body-copy-small;
}

p {
	padding-bottom: $base-size*2;
}

button:active,
a:active,
a:focus,
.slick-slide,
.slick-slide:active {
	outline: none;
	border: none;
	outline-color:transparent;
	outline-style:none;
}


@include gen-grid(12,$gutter, $container);
@include gen-grid(12,$gutter,calc(100% - (#{$offset-mobile}*2)),'mob-','mobile');



.social-section {

	.big-bird & {
		border-left:1px solid $line-color-green;
		padding:0*$base-size 0 8*$base-size 0;


		@media only screen  and (min-width : $breakpoint-tablet) {
			padding:8*$base-size 0;
		}
	}

	.contact-page & {
		padding-bottom: 6*$base-size;
		margin-top: -4*$base-size;
	}

	.social-list {
		list-style-type: none;
		display: inline-block;

		.big-bird & {
			width: col-width(12,$gutter,$container,11);
			padding-left:col-width(12,$gutter,$container,1);
			padding-right:40px;
			margin-left: $gutter;

			@media only screen  and (min-width : $breakpoint-desktop-medium) {
				width: col-width(12,$gutter,$container,4);
				margin-left: 0;
			}
		}

		li {
			display: inline-block;
			margin-right: 2*$base-size;
			overflow: hidden;

			.contact-page & {
				border-top:1px solid $line-color-green;
				padding:1.5*$base-size 0;
				margin-left: 0;

				&:nth-child(1),
				&:nth-child(2) {
					border-top: none;
				}
			}

			.big-bird & {
				display: block;
				padding:1.5*$base-size 0;
				border-top:1px solid $line-color-green;

				&:nth-child(1) {
					border-top: none;
				}
			}

			a {
				height: 3.75*$base-size;
				display: inline-block;
				padding-left: 2*$base-size;
				text-decoration: none;
				font-weight: 400;
				font-family: $sans-serif-text;
				float: left;
				line-height: 4.5*$base-size;


				.big-bird & {
					color: $darkest-green;
				}

			}

			i {
				width:4.5*$base-size;
				height:4.5*$base-size;
				display: inline-block;
				background-size: contain !important;
				float: left;
			}

			&.linkedin i {
				background:url('../../img/icon-linkedin.svg') center center no-repeat;


				.contact-page &,
				.big-bird & {
					background:url('../../img/icon-linkedin-green.svg') center center no-repeat;
				}

				background-size: contain;
			}

			&.twitter i {
				background:url('../../img/icon-twitter.svg') center center no-repeat;

				.contact-page &,
				.big-bird & {
					background:url('../../img/icon-twitter-green.svg') center center no-repeat;
				}

				background-size: contain;
			}

			&.facebook i {
				background:url('../../img/icon-facebook.svg') center center no-repeat;

				.contact-page &,
				.big-bird & {
					background:url('../../img/icon-facebook-green.svg') center center no-repeat;
				}

				background-size: contain;
			}

			&.instagram i {
				background:url('../../img/icon-instagram.svg') center center no-repeat;

				.contact-page &,
				.big-bird & {
					background:url('../../img/icon-instagram-green.svg') center center no-repeat;
				}

				background-size: contain;
			}

			&.youtube i {
				background:url('../../img/icon-youtube.svg') center center no-repeat;

				.contact-page &,
				.big-bird & {
					background:url('../../img/icon-youtube-green.svg') center center no-repeat;
				}

				background-size: contain;
			}
		}
	}
	
}

.boxes {
	&.is-centered {
		text-align: center;
		margin:0 auto;
		
	}
}

.box {
	position: relative;

	.img-box {
		margin-bottom: 3*$base-size;
	}


	.icon {
		background-color:$light-green;
		border-radius: 100%;
		width: 9.375*$base-size;
		height: 9.375*$base-size;
		display: inline-block;
		float: left;
		margin-right: 2.5*$base-size;
		display: flex;
		align-items: center;
		justify-content: center;


			img {
				width: 40%;
				height:auto;
				margin-bottom: 0;
			}
		

	}

		.supporting-farmers .icon {
	    	background-color: $blue;

	    	img {
	    		filter: brightness(0) invert(1);
	    	}
	    }

	    .protecting-the-environment .icon {
	    	background-color: $green;

	    	img {
	    		filter: brightness(0) invert(1);
	    	}
	    }

	    .ensuring-health-safety .icon {
	    	background-color: $orange;

	    	img {
	    		filter: brightness(0) invert(1);
	    	}
	    }

	    .delivering-food-feed-fibre .icon {
	    	background-color: $yellow;
	    	
	    	img {
	    		filter: brightness(0) invert(1);
	    	}
		}

	.text {
		position: relative;
	}

	.tag-box {


		.tiny-headline {
			display: inline-block !important;

			.tag {
				display: inline-block !important;
		
				font-family: $sans-serif;
			}
		}

		.tag {
			display: inline-block !important;

			font-family: $sans-serif;
		}
	}

	&.solid {
		background-color: $warm-grey;
		padding: 2.5*$base-size;
		overflow: hidden;
		min-height: 12*$base-size;
		border-bottom: 1px solid darken($warm-grey,6%);

		@media only screen  and (max-width : $breakpoint-tablet) {
			width: 90% !important;
			margin-left: 5%;
		}

		@media only screen  and (min-width : $breakpoint-desktop) {
			border-bottom: none;
		}

		@media only screen  and (min-width : $breakpoint-desktop-medium) {
			min-height: 16*$base-size;

		}


		a {
			display: block;
			overflow: hidden;
			display: flex;
			align-items: center;
  			
  			@media only screen  and (min-width : $breakpoint-desktop) {
				justify-content: center;
			}
		}



		.text {
			float: left;
			width: 70%;
		}

		.tiny-headline {
			display: inline-block;
			padding-bottom: 0.75*$base-size;
		}

		.heading {

		}

		h3 {
			margin-bottom:0.75*$base-size;
		}

	}

	&.vertical-box {
		vertical-align: top;
		position: relative;

		.img-box {
			margin-bottom: 2*$base-size;

			@media only screen  and (min-width : $breakpoint-tablet) {
				margin-bottom: 5*$base-size;
			}
		}

		.icon {
			background-color: $light-green;
			position: absolute;
			width: 60px;
			height: 60px;
			width: 9.375*$base-size;
			height: 9.375*$base-size;
			display: inline-block;
			position: absolute;
			right: 0*$base-size;
			top: -8*$base-size;
			display: flex;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;
		    justify-content: center;

		}

		.crop-protection .icon {
			background-color: $light-green;
		}

		.text {
			text-align: left;

			@media screen and (min-width: $breakpoint-tablet) {
				padding-right:col-width(12,$gutter,$container,1);
				padding-left:0.5*$gutter;
			}
		}


		&.col-3-3 {
			width: 100%;
			display: inline-block;
			 

			@media screen and (min-width: $breakpoint-tablet) {
				margin-bottom:3*$base-size;
			}
			
			@media screen and (min-width: $breakpoint-tablet) {
				width: col-width(12,$gutter,$container,3.3);
				margin-right:$gutter;
			}

			&:last-child {
				margin-right:0;
			
				@media screen and (max-width : $breakpoint-mobile-end) {
					margin-top:6*$base-size;

					a {
						width: 100%;
					}
				}
			}

			.img-box {
				margin-bottom: 3*$base-size;
			}

			.icon img {
				margin-bottom: 0;
			}

			.tiny-headline {
				padding-bottom: 1*$base-size;
			}
		}

		&.col-3 {
			.img-box {
				margin-bottom: 3*$base-size;
			}

			.text {
				padding-right:2*$base-size;
			}


		}
	}

	&.horizontal-box  {
		overflow: hidden;
		margin-bottom: $gutter;
		padding-top: $gutter;
		border-top:1px solid $line-color;


		&:nth-child(1) {
			border-top: 0;
			padding-top: 0;
		}

		a {
			position: relative;
		}

		.icon {

		    .supporting-farmers & {
		    	background-color: $blue;
		    }

		    .protecting-the-environment & {
		    	background-color: $green;
		    }

		    .ensuring-health-safety & {
		    	background-color: $orange;
		    }

		    .delivering-food-feed-fibre & {
		    	background-color: $yellow;
			}
		}

		.small-headline {
			padding-top: 0.5*$base-size;
		}


		.tag-box {
			padding-bottom: 1*$base-size;
		}



		.tiny-headline {
			display: block;


			.tag {
				display: inline-block !important;
			}
		}

		img {
			float: left;
			width:col-width(12,$gutter,$container,5);
			height: auto;

			@media screen and (min-width: $breakpoint-tablet) {
				width:col-width(12,$gutter,$container,2.5);
			}

			@media screen and (max-width : $breakpoint-mobile-end) {
				.industry-news.color-scheme-light-green & {
					width: 100%;
				}
			}
		}

		.text {
			float: left;
			margin-left: 1.5*$gutter;
			position: absolute;
			left:col-width(12,$gutter,$container,5) !important;
			top:0;
			bottom:0;
			right: 0;
			display: flex;
			align-items: center;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;

		    @media screen and (min-width: $breakpoint-tablet) {
				left:col-width(12,$gutter,$container,2.5) !important;
			}

			@media screen and (max-width : $breakpoint-mobile-end) {
				.industry-news.color-scheme-light-green & {
					width: 100%;
					position: initial;
					display: block;
					margin-left: 0;
				}
			}
		}
	}

	a {
		text-decoration: none;
		display: block;
		overflow: hidden;
	}
	img {
		width:100%;
		height: auto;
	}

	.text {

	}
}

.read-more-btn {
	display: block;
	text-decoration: none;
	float: right;
	padding-right:2.5*$base-size;
	position: relative;

	&:after {
		background:url(../../img/icon-arrow-plain.svg) no-repeat;
		height: 1.1*$base-size;
		width: 1*$base-size;
		background-size:contain;
		content: ' ';
		position: absolute;
		right: 0;
		display: block;
		top: 0.6*$base-size;
	}
}

.top-image {
	//padding: 10vw;
	height: 76vh;

	.has-top-image & {
		@media only screen  and (min-width : $breakpoint-tablet-landscape) {
			margin-top: 4*$base-size;
		}
	}

	.has-top-image  .campaign-active & {
		@media only screen  and (min-width : $breakpoint-tablet-landscape) {
			margin-top: 0;
		}
	}

	.single & {
		height: 50vh;
	}
}

.top-boxes {
	margin-top: -5*$base-size;
	margin-bottom: 4*$base-size;

	.icon {
		background-color: white !important;

		img {
			filter:none !important;
			opacity: 1;
		}
	}

	.heading {
		font-size: 1.6rem !important;

	 	@media only screen  and (min-width : $breakpoint-desktop-medium) {
			font-size: 1.8rem !important;
		}
	
	}
}

.color-block {
	background: white;
	min-height: 200px;
	position: relative;

	a {
		text-decoration: none;
		display: block;
		padding: 1.5*$gutter;
	}

	.icon {
		width: 9.25*$base-size;
		height: 9.25*$base-size;
		border-radius: 100%;
		position: absolute;
		top: -4.625*$base-size;
		left: 50%;
		margin-left: -4.625*$base-size;
		display: block;
		background-color: $light-green;
		display: flex;
		align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: none;
	}
}

.denied {
	padding-top: 10*$base-size;
}

.staff {
	@media only screen  and (min-width : $breakpoint-tablet) {
		padding-bottom: 10*$base-size;
	}

	.img-box {
		margin-bottom:2*$base-size;
	img {
		width:100%;
		height: auto;
		}
	}

	.medium-headline {
		padding-top: 2em;
		padding-bottom: 0;
		border-top:1px solid $line-color;
	}

	&:nth-child(1) .medium-headline {
		border-top:none;
		padding-top: 0;
	}

	.staff-title {
		@extend .small-headline;
		font-weight: 100;
		padding-top: 0;
		padding-bottom: 2em;

	}

}


.v-center {
	position: relative;
	top: 50%;
  	transform: translateY(-50%);
}

.mobile-section-title {
	display: none;
}

.rounded-menu {
	padding: 2*$base-size 0 6*$base-size 0;
	overflow: hidden;

	@media only screen  and (min-width : $breakpoint-mobile-end) {
		padding: 10*$base-size 0 0 0;
	}
}

.round {
	width:col-width(12,$gutter,$container,2,3.5);
	height:col-width(12,$gutter,$container,2,3.5);
	display: inline-block;
	margin-top: 6*$base-size;
	
	@media only screen  and (max-width : $breakpoint-mobile-end) {
		margin-bottom: 6*$base-size;

		&:nth-child(odd) {
			float: left;
		}

		&:nth-child(even) {
			float: right;
			margin-right: 2rem;
		}

	}

	@media only screen  and (min-width : $breakpoint-tablet) {
		width:col-width(12,$gutter,$container,2,2);
		height:col-width(12,$gutter,$container,2,2);
		margin-top: 0;
		margin-left: $gutter;
	}

	&:nth-child(1) {
		margin-left: 0;
	}

	
		&:nth-child(1) {
			.icon {
				background: url('../../img/icon-health-round-green.svg');
				background: url('../../img/icon-health-round-orange.svg');
				background-size:contain;
			}
		}

		&:nth-child(2) {
			.icon {
				background: url('../../img/icon-feeding-round-green.svg');
				background: url('../../img/icon-feeding-round-yellow.svg');
				background-size:contain;
			}
		}

		&:nth-child(3) {
			.icon {
				background:url('../../img/icon-supporting-round-green.svg');
				background:url('../../img/icon-supporting-round-blue.svg');
				background-size:contain;
			}
		}

		&:nth-child(4) {
			.icon {
				background:url('../../img/icon-environmental-round-green.svg');
				background-size:contain;
			}
		}
	
}





.rounded-img {
	border-radius: 100%;
	width:col-width(12,$gutter,$container,2,3.5);
	height:col-width(12,$gutter,$container,2,3.5);
	background-color: $darkest-green;
	overflow: hidden;


	@media only screen  and (min-width : $breakpoint-tablet) {
		width:col-width(12,$gutter,$container,2,2);
		height:col-width(12,$gutter,$container,2,2);
	}

	img {
		width: 100%;
		height: auto;
	}
	
}

.is-centered {
	text-align: center;
	margin: 0 auto !important;
}

.rounded-menu {
	text-align: center;
	margin: 0 auto !important;

	li {
		position:relative;

		span {
			font-family:$sans-serif;
		}
	}
	

	.icon {
		display: block;
		background-color: $light-green;
		border-radius: 100%;
		width: 9.375*$base-size;
		height: 9.375*$base-size;
		display: inline-block;
		position: absolute;
		right: -2*$base-size;
		top: 2*$base-size;
	}

	a {
		text-decoration: none;
	}

	h4 {
		margin-top: 3*$base-size;
		font-weight: 400;
		font-family:$sans-serif-medium;

		@media only screen  and (min-width : $breakpoint-tablet) {
			margin-top: 5*$base-size;
		}

		span {
			font-weight: 200;
			display: block;
		}
	
	}
}


.stretch-children {

  	 @media only screen  and (min-width : $breakpoint-tablet) {
		align-items: stretch;
	    display: flex;
	  	justify-content: center;
	}
}

.color-block-question {
	overflow: visible;

	h3 {
		font-size: 3*$base-size;
		color: white;
		bottom: 5*$base-size;
		padding-right: 4*$base-size;
		line-height: 1.2;

		@media only screen  and (min-width : $breakpoint-tablet-landscape) {
			font-size: 3.5*$base-size;
		}

		@media only screen  and (min-width : $breakpoint-desktop) {
			font-size: 2.5*$base-size;
		}

		@media only screen  and (min-width : $breakpoint-desktop-medium) {
			font-size: 3*$base-size;
		}

		@media only screen  and (min-width : $breakpoint-desktop-large) {
			font-size: 3.5*$base-size;
		}
	}

	&.item-6 h3 {
		@media only screen  and (min-width : $breakpoint-tablet-landscape) {
			font-size: 3.5*$base-size;
		}

		@media only screen  and (min-width : $breakpoint-desktop) {
			font-size: 2.5*$base-size;
		}

		@media only screen  and (min-width : $breakpoint-desktop-medium) {
			font-size: 3*$base-size;
		}

		@media only screen  and (min-width : $breakpoint-desktop-large) {
			font-size: 3.5*$base-size;
		}
	}

	.tiny-headline {
		display:none !important;
	}

	a {
		padding: 9*$base-size 5*$base-size 4*$base-size 5*$base-size;
		background-color:$yellow;
		display: table;
	    height: 100%;
	    overflow: visible;

	    @media only screen  and (min-width : $breakpoint-tablet) {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 6*$base-size 5*$base-size 4*$base-size 5*$base-size;
		}

	    &.supporting-farmers {
	    	background-color: $blue;

	    	.icon {
	    		background-color: lighten($blue,8%);
	    	}

	    	.tag {
	    		color: darken($blue,25%);
	    	}
	    }

	    &.protecting-the-environment {
	    	background-color: $green;

	    	.icon {
	    		background-color: darken($green,8%);
	    	}

	    	.tag {
	    		color: darken($green,25%);
	    	}
	    }

	    &.ensuring-health-safety {
	    	background-color: $orange;

	    	.icon {
	    		background-color: darken($orange,8%);
	    	}

	    	.tag {
	    		color: darken($orange,25%);
	    	}
	    }

	    &.delivering-food-feed-fibre {
	    	background-color: $yellow;

	    	.icon {
	    		background-color: darken($yellow,8%);
	    	}

	    	.tag {
	    		color: darken($yellow,25%);
	    	}
	    }
	}

	.text {
		padding-right:0;
		display: table-cell;
		padding: 0;
		vertical-align: middle;
		position: static;
		padding-top:2*$base-size;
	}


	.icon {
		position: absolute;
		top: -4*$base-size;
		left: 50%;
		margin-left: -4.625*$base-size;
		background-color: darken($yellow,8%);
		width: 9.375*$base-size;
		height: 9.375*$base-size;
		display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    justify-content: center;

		img {
			display: block;
			margin-bottom: 0;
			filter: brightness(0) invert(1);

		}
	}

	.tiny-headline {
		display: none;

	}

	.tag {
		width: 100%;
		display: block;
		text-align: center;
		left: 0;
		position: absolute;
		top: 8*$base-size;
	}

	img {
		display: none;
	}
	
}

.boxes .question {
	@extend .color-block-question;
}

.down-arrow {
	height: 20px;
	clear: both;
	text-align: center;
	padding: 3*$base-size 0 5*$base-size 0;
	background:url(../../img/icon-down.svg) no-repeat top center;
	overflow:hidden;

	@media only screen  and (max-width : $breakpoint-mobile-end) {
	   display: none;
	}
}


.filter-menu {
	padding: 2*$base-size 0;
	border-top:1px solid $light-grey;
	border-bottom:1px solid $light-grey;
	margin-bottom:  4*$base-size;

	.page-template-template-strategy-search & {
		margin-bottom:  2*$base-size;
	}

	a {
		text-decoration: none;
	}

	.tag-box {
		@media only screen  and (max-width : $breakpoint-tablet) {
	    margin-left: 10*$base-size;
	  }

	  @media only screen  and (max-width : $breakpoint-mobile-end) {
	    margin-left: 2*$base-size;
	  }


	}
}

.tag-box {
	padding-top: 0.375*$base-size;
}

.related-info-box {
    padding:4*$base-size 1*$base-size 2*$base-size 3*$base-size;
    overflow: visible;
    float: left;
    margin:0.75*$base-size 3*$base-size 3*$base-size 0;
    background-color: $yellow;
    color: white;
    width: 100%;
    transition: width 0.5s ease;
    position: relative;

	@media only screen  and (min-width : $breakpoint-tablet) {
		margin:$base-size 4*$base-size 4*$base-size -12%;
		padding:3*$base-size 5*$base-size 3*$base-size 8*$base-size;
		width: 112%;
	}


    h3, p ,h4, .tiny-headline {
    	color: white;
	}

	&.right {
		 float: right;
		 margin:$base-size 0 $base-size 4*$base-size;

		 @media only screen  and (min-width : $breakpoint-desktop) {
			margin:$base-size  -12% 4*$base-size 6*$base-size;
		}

	}


	&.collapsed .more-info {
	    height: 1px;
		opacity: 0;
		transition: all 0.1s ease;
		overflow: hidden;
	}

	.more-info {
	    height: auto;
	    opacity: 1;

	    a {
	    	color: $medium-grey;
	    }
	}

	.heading {
		transition: all 0.5s ease;
		font-size: 3.5*$base-size;
		padding-top:0.5*$base-size;
	}

	&.collapsed .heading {
	    font-size: 2*$base-size;
	}



	&.supporting-farmers {
		background-color: $blue;

		.icon, .btn-toggle {
			background-color: lighten($blue,8%);
		}

		.tag, .tiny-headline {
			color: darken($blue,25%);
		}
	}

	&.protecting-the-environment,
	&.biotechnology {
		background-color: $green;

		.icon, .btn-toggle {
			background-color: darken($green,8%);
		}

		.tag, .tiny-headline {
			color: darken($green,25%);
		}
	}

	&.ensuring-health-safety {
		background-color: $orange;

		.icon {
			background-color: darken($orange,8%);
		}

		.tag, .tiny-headline {
			color: darken($orange,25%);
		}
	}

	&.delivering-food-feed-fibre {
		background-color: $yellow;

		.icon, .btn-toggle {
			background-color: darken($yellow,8%);
		}

		.tag, .tiny-headline {
			color: darken($yellow,25%);
		}
	}

    .icon {
    	position: absolute;
		background-color: darken($yellow,8%);
		border-radius: 100%;
		width: 7*$base-size;
		height: 7*$base-size;
		display: inline-block;
		display: flex;
		align-items: center;
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    justify-content: center;
	    top: -3*$base-size;

	    img {
	    	width: 20px;
	    	height: auto;
	    }

	    @media only screen  and (min-width : $breakpoint-tablet) {
	    	width: 9.375*$base-size;
			height: 9.375*$base-size;
			left: -4.5*$base-size;
			top: 1.6*$base-size;

			img {
		    	width: 35px;
		    	height: auto;
		    }
		}

    }


    .tag, .tiny-headline {
    	display: inline-block;
    	color: darken($yellow,25%);
    }

    &.collapsed {
    	width: col-width(12,$gutter,$container,8);

    	@media only screen  and (min-width : $breakpoint-tablet) {
			width: col-width(12,$gutter,$container,5);
		}
    }

    .btn-toggle {
    	width: 5*$base-size;
    	height:5*$base-size;
    	border-radius:5*$base-size;
    	margin-top: -1*$base-size;
    	margin-right: -3*$base-size;
    	display: inline-block;
    	float: right;
    	background-color: darken($yellow,20%);
    	cursor: pointer;
    	display: block;
    	transition: all 0.5s ease;
    	display: flex;
		align-items: center;
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    justify-content: center;

	    @media only screen  and (max-width : $breakpoint-mobile-end) {
	    	transform: scale(0.8);
	    	margin-top: -3*$base-size;
	    	margin-right: 0;

	    }

    	i {
    		background:url(../../img/icon-plus.svg) no-repeat top center;
    		background-size: contain;
    		width: 2.5*$base-size;
    		height: 2.5*$base-size;
    		display: block;
    		opacity: 0.8;
    		-ms-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
    	}

    }

	&.collapsed .btn-toggle i {
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		
	}
}

.grid .gform_wrapper {
	width: col-width(12,$gutter,$container,12);
	margin: 0 auto;

	@media only screen  and (min-width : $breakpoint-tablet) {
		width: col-width(12,$gutter,$container,8);
	}

	input[type="submit"] {
		@extend .rounded-btn;
	}

	input[type="text"],
	input[type="email"] {
		height:5.625*$base-size;
		padding-left: 1*$base-size;
		margin-bottom: 0.5*$base-size;
	}

	.top_label .gfield_label {
		@extend .form-lables;
	}
}

.custom-pagination {
	text-align: center;
	padding-top:3*$base-size;
	padding-bottom: 5*$base-size;


	.page-numbers {
		text-decoration: none;
		display: inline-block;
		padding:1*$base-size 1*$base-size 0.5*$base-size 1*$base-size;

		&.current {
			color: $dark-green;
			font-weight: 400;
			position: relative;
			font-family: $sans-serif-medium;

			&:after {
				width:40%;
				height: 2px;
				background-color:$dark-green;
				position: absolute;
				bottom: 0;
				content: ' ';
				left: 50%;
				margin-left: -20%;
			}
		}

		&.next {
			float: right;
			
			i {
				background:url(../../img/icon-arrow-plain.svg) no-repeat bottom center;
				width: 2*$base-size;
				height: 2*$base-size;
				display: inline-block;
			}
		}

		&.current {

		}

	}
}

.media-grid {
	.box {
		&.first {
			float: left;
			.heading {
				@extend .medium-headline;
			}
		}

		&.horizontal-box.item-2 {
			padding-top: 0;
			border-top: none;
		}

		.img-box {
			float: left;
			img {
				@media only screen  and (max-width : $breakpoint-mobile-end) {
					width: 100%;
				}
			}

			@media only screen  and (max-width : $breakpoint-mobile-end) {
				padding-bottom:2*$base-size;
			}
		}

		.icon {
			position: absolute;
		}

		&.horizontal-box {

			@media only screen  and (max-width : $breakpoint-mobile-end) {
				width: 100%;
			}

			.heading {
				@extend .small-headline;
				padding-top: 1*$base-size;
			}

			&.item-2 .heading,
			&.item-3 .heading,
			&.item-4 .heading {
				font-size: 2rem;
				margin-top:0;
				padding-top: 0;
			}

			.text {
				width: 60%;
				
				@media only screen  and (max-width : $breakpoint-mobile-end) {
					width:100%;
					position: initial;
					left: auto;
					margin-left: 0;

				}
			}
		}

		&.item-2,
		&.item-3,
		&.item-4 {
			float: left;

			.text {
				margin-left:6*$base-size;
				float: left;
				width: 50%;

				@media only screen  and (max-width : $breakpoint-mobile-end) {
					width: 100%;
					margin-left: 0;
				}

				.heading {
					//float: left;
				}
			}
		}
	}


}

.question-grid {
	margin-bottom: 4rem;
    overflow: hidden;
	@media only screen  and (max-width : $breakpoint-tablet) {
	    .col-3 {
	    	margin-left: 0;
	    }
    }
}

.question-grid,
.related-material {

	.item-row:nth-child(1) {
		margin-bottom: 5*$base-size;
	}

	.item-row:nth-child(2),
	.item-row:nth-child(3),
	.item-row:nth-child(4) {
		@extend .stretch-children;
		justify-content:normal;

		@media only screen  and (max-width : $breakpoint-mobile-end) {

		}
		
	}

	.first {
		float: left;

		@media only screen  and (max-width : $breakpoint-mobile-end) {
			float: none;
		}
	}

	.first .heading {
		@extend .medium-headline;
		padding-top: 0;
	}

	.box {
		padding-bottom: 2*$base-size;
		margin-bottom: 2*$base-size;



		@media only screen  and (min-width : $breakpoint-tablet) {
			padding-bottom: 3*$base-size;
			margin-bottom: 3*$base-size;
		}

		.icon {
			position: absolute;
			top: -6*$base-size;
			right: 0;
			width: 5rem;
			height: 5rem;
		}

		&.color-block-question .icon {
			width:9.375*$base-size;
			height:9.375*$base-size;
		}

		.tiny-headline {
			padding-bottom: 1.5*$base-size;
		}

		&.first {
			.tiny-headline {
				padding-bottom: 3*$base-size;
			}


			.icon {
				position: absolute;
			    top: -10rem;
			    right: 1rem;
			    width: 10*$base-size;
				height: 10*$base-size;
			}


			.supporting-farmers .icon {
		    	background-color: $blue;

		    	img {
		    		filter: brightness(0) invert(1);
		    	}
		    }

		    .protecting-the-environment .icon {
		    	background-color: $green;

		    	img {
		    		filter: brightness(0) invert(1);
		    	}
		    }

		    .ensuring-health-safety .icon {
		    	background-color: $orange;

		    	img {
		    		filter: brightness(0) invert(1);
		    	}
		    }

		    .delivering-food-feed-fibre .icon {
		    	background-color: $yellow;

		    	img {
		    		filter: brightness(0) invert(1);
		    	}
			}
		}
	}

	.color-block-question {

		@media only screen  and (min-width : $breakpoint-tablet-landscape) {
			min-height: 250px;
		}
			
		@media only screen  and (min-width : $breakpoint-desktop) {
			min-height: 300px;
		}

		@media only screen  and (min-width : $breakpoint-desktop-medium) {
			min-height: 400px;
		}

		@media only screen  and (max-width : $breakpoint-mobile-end) {
			margin-top:4rem;
			margin-bottom:2rem;
			padding-bottom:0;
		}

		a {
			width: 100%;
	

			@media only screen  and (min-width : $breakpoint-tablet-landscape) {
				min-height: 250px;
			}
			
			@media only screen  and (min-width : $breakpoint-desktop) {
				min-height: 300px;
			}

			@media only screen  and (min-width : $breakpoint-desktop-medium) {
				min-height: 400px;
			}
		}

		.tiny-headline {
			display: none;
		}

		.tag {
			display: block;
			font-size: 1.5*$base-size;
		}

		.icon {
			top: -4*$base-size;


		}
	}
}

.related-material h4 {
	@media only screen  and (min-width : $breakpoint-tablet) {

		.question-template-default & {
			margin-left:$gutter;
		}

		.page-template-strategy-search & {
			margin-left: 0;
		}
	}
}



.filter-menu.tag-menu {
	padding: 0;
	position: relative;
}

.filter-links {
	text-align: center;

	@media only screen  and (max-width : $breakpoint-mobile-end) {
		overflow: hidden;
	}

}

.filter-link {
	font-size: 2*$base-size;
	font-weight: 300;
	display: inline-block;
	padding: 2.2*$base-size 4*$base-size 1.8*$base-size 3*$base-size;
	border-left: 1px solid $line-color;


	&.active {
		
		&.ensuring-health-safety {
			background-color: lighten($orange,35%);
		}

		&.delivering-food-feed-fibre {
			background-color: lighten($yellow,20%);
		}

		&.protecting-the-environment {
			background-color: lighten($green,35%);
		}

		&.supporting-farmers {
			background-color: lighten($blue,30%);
		}

	}

	@media only screen  and (max-width : $breakpoint-mobile-end) {
		padding: 0;
		width: 50%;
		float: right;
		text-align: left;
		height: 8*$base-size;
		position: relative;
		display: flex;
	  	align-items: center;
	  	border-left: 0;
	  	overflow: hidden;
	 	

		a {
			padding: 2*$base-size 1*$base-size 2*$base-size 6*$base-size;
			display: block;
		}

		.term-count {
			position: absolute;
			top: 2.5*$base-size;
			left: 1*$base-size;
			padding-top: 0*$base-size;
		}

		&:nth-child(3),
		&:nth-child(2) {
			border-top: none;
		}

		&:nth-child(3),
		&:nth-child(5) {
			border-left: none;
		}

		&:nth-child(2),
		&:nth-child(3) {
			border-bottom: 1px solid $line-color;
		}
	}

	&.see-all {
		float: left;
		border:none;
		padding-left:0;

		@media only screen  and (max-width : $breakpoint-mobile-end) {
			position: absolute;
			left: $gutter;
			display: none;
		}

		a {
			font-weight: 400;
			font-family: $sans-serif-text;
		}
		
	}
}

.term-count {
	color: white;
	display: inline-block;
	width: 2.5*$base-size;
	height: 2.5*$base-size;
	border-radius: 2.5*$base-size;
	text-align: center;
	font-size: 1.5*$base-size;
	padding-top: 0.3*$base-size;
	position: relative;
	top: -0.25*$base-size;
	margin-right: 1.5*$base-size;
	font-weight: 400;
	font-family: $sans-serif-medium;

		@media only screen  and (max-width : $breakpoint-mobile-end) {
			line-height: 1.8;
		}

	.supporting-farmers & {
    	background-color: $blue;


    	.icon {
    		background-color: lighten($blue,8%);
    	}

    	.tag {
    		color: darken($blue,25%);
    	}
    }

    .protecting-the-environment & {
    	background-color: $green;

    	.icon {
    		background-color: darken($green,8%);
    	}

    	.tag {
    		color: darken($green,25%);
    	}
    }

    .ensuring-health-safety & {
    	background-color: $orange;

    	.icon {
    		background-color: darken($orange,8%);
    	}

    	.tag {
    		color: darken($orange,25%);
    	}
    }

    .delivering-food-feed-fibre & {
    	background-color: $yellow;

    	.icon {
    		background-color: darken($yellow,8%);
    	}

    	.tag {
    		color: darken($yellow,25%);
    	}
    }
}

.divider {
	clear: both;
}

.related-material {
	background-color: $warm-grey;
	padding: 6*$base-size 0 4*$base-size 0;
	margin-top:6*$base-size;

	.question .tiny-headline,
	.page .tiny-headline {
		display: none !important;
	}



	.question .tag,
	.page .tag {
		display: inline-block !important;
	}

	h4 {
		@extend .small-headline;
		padding-bottom: 4*$base-size;
	}

	&.grid .box.col-4:nth-child(1) {
		margin-left: 0;
	}

	.stretch-children {
		justify-content: flex-start;
	}

	.box.color-block-question h3 {
		font-size: 3.5*$base-size;
	}

	.col-4 {
		@media only screen  and (max-width : $breakpoint-tablet-landscape) {
			width: 100%;
			margin-left: 0;
		}
	}
}

.wrapper .rich-text {
	padding-bottom:4*$base-size;

	@media only screen  and (min-width : $breakpoint-tablet) {
		padding-bottom:10*$base-size;
	}
	
}

.inline-image {
	margin-bottom:3*$base-size;

	img {
		width: 100%;
		height: auto;
	}

	&.fullwidth {

	}

	&.left {
		float: left;
		width:100%;
		margin-top:0.5*$base-size;

		@media only screen  and (min-width : $breakpoint-tablet) {
			width:col-width(12,$gutter,$container,4);
			margin-right:$gutter;
		}
	}

	&.right {
		float: right;
		margin-top:0.5*$base-size;
		width:100%;

		@media only screen  and (min-width : $breakpoint-tablet) {
			width:col-width(12,$gutter,$container,4);
			margin-left:$gutter;
		}
	}
}

.image-caption,
.video-caption {
	@extend .caption;
	border-bottom: 1px solid $line-color;
	padding-bottom:2*$base-size;
	padding-top:1*$base-size;

}

.video-box {
	margin-top:2*$base-size;
	margin-bottom:2*$base-size;
}
.video-wrap {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
	margin-bottom:1*$base-size;

	iframe {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
	}
}

a.pdf {
	@extend .rounded-btn;
	display: inline-block;
	margin-left: -1*$gutter;
	position: relative;

	&.download {
		margin-left:0;
		margin-top: 1.5*$gutter;
		margin-bottom: 4*$gutter;
	}

	&.related-btn {
		margin-bottom: 0.5*$gutter;
		margin-top: 0.5*$gutter;
		background-color: darken($yellow,20%);

		.biotechnology & {
			background-color: darken($green,20%);
		}
	}

	.tiny {
		font-size: $base-size;
		text-transform: uppercase;
		top: -3px;
		left: 3px;
		position: relative;
		color: white;
	}
}

h3 .tiny {
	font-size: $base-size;
	text-transform: uppercase;
	top: -3px;
	left: 3px;
	position: relative;
	color: white;
	color: $darkest-green;
}

.related-documents {
	background-color: $light-green;
	padding: 5*$base-size 5*$base-size 3*$base-size 5*$base-size;

	&.disabled {
		display: none;
	}

	h3 {
		@extend .small-headline;
		font-size: 2.5*$base-size;
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 3*$base-size;
		position: relative;
		line-height: 1.1;

		&:after {
			content:' ';
			display:block;
			position: absolute;
			width: 30%;
			left: 0;
			bottom: -2px;
			height: 4px;
			background-color: $dark-green;

		}
	}

	a {
		text-decoration: none;
		@extend .sub-heading-small;
		padding:$base-size*1.75 0;
		margin-bottom: 0;
		border-top: 1px dashed $line-color-green;

		&:nth-child(1) {
			padding-top:$base-size*2.5;
		}

	}

	.subnav li {
		padding: 0;
		list-style-type: none;
		margin-left: 0;
		margin-bottom: 0;

	}
}



.single-resistance-strategy {
	ol {
		margin-top: 4*$base-size;
		margin-left: -2*$base-size;
	}

	.filter-menu {
		margin-bottom:6*$base-size;
	}
}

#search-query-info {
	border-bottom: 1px solid $line-color;
	width: 100%;
	padding-bottom:2*$base-size;

	.results-text {
		display: inline-block;
	}

	.results-number {
		font-weight: 400;
		margin-right: 4px;
	}
}


.fine-print {
	border-top: 1px solid $line-color;
	padding-bottom: 6*$base-size;
	padding-top: 6*$base-size;
}

.row {
	&.campaigns {
		margin-top: 8*$base-size;
		margin-bottom: 12*$base-size;
	}
}

.row .col-12:first-child,
.row .col-11:first-child,
.row .col-10:first-child,
.row .col-9:first-child,
.row .col-8:first-child,
.row .col-7:first-child,
.row .col-6:first-child,
.row .col-5:first-child,
.row .col-4:first-child,
.row .col-3:first-child,
.row .col-2:first-child,
.row .col-1:first-child {
	//margin-left: 0;
}

.agent-text {
	font-weight: 400;
	text-transform: capitalize;
	font-size: 2rem;
}

.uppercase {
	text-transform: uppercase;
}

.address,
.address p,
.phone,
.office-hours {
	color: $text-color;
	@extend .body-copy-small;

	.footer & {
		color: white;
	}
}

.address {
	padding-bottom: 2*$base-size;
	
}

.footer .address {
	padding-bottom: 0;
}

.phone {
	margin-bottom:2*$base-size;
}

.industry-news h2 {
	padding-bottom: 2rem;
	

	@media only screen  and (min-width : $breakpoint-mobile-mid) {
		margin-left: 5%;
	}
	@media only screen  and (min-width : $breakpoint-mobile-end) {
		margin-left: 3.5rem;
	}
}



.item-row, .row .media-grid {
	position: relative;
	clear: both;
    float: left;
    width: 100%;
	
	@media only screen  and (max-width : $breakpoint-tablet-landscape) {
		&:nth-child(1) {
			overflow: hidden;
		}
	}

    .col-3,
    .col-6 {
   		@media only screen  and (max-width : $breakpoint-mobile-end) {
			width: 100%;
			margin-left: 0;
		}
    }
}

.question.item-2, .question.item-3 , .question.item-5 {
	@media only screen  and (min-width : $breakpoint-tablet) {
		width: col-width(12,$gutter,$container,3);
		float: left;
	}
}

.question.item-3, .question.item-5  {

	@media only screen  and (min-width : $breakpoint-tablet) {
		float: right;
	}
}


.item-4.color-block-question {
	@media only screen  and (min-width : $breakpoint-tablet) {
		width: col-width(12,$gutter,$container,3);
		float: left;
	}
}

.right-side,
.left-side {
	@media only screen  and (max-width : $breakpoint-tablet-landscape) {
		width: 100% !important;
		margin-left: 0  !important;


		.col-3 {
			margin-left: 0 !important;
		}
	}
}

.half-box {
	clear: both;
    float: left;
    width: 100%;
}

.img-box {
	position: relative;
}

.media-grid {
	.box.first {
		.img-box {
			width: 100%;

			.icon {
				top: auto;
				bottom: -4rem;
			}
		}
	}

	.icon {
		display: none;
	}

	.horizontal-box {
		.img-box {
			margin-bottom:0;

			@media only screen  and (max-width : $breakpoint-mobile-end) {
				width:100%;
			}
		}
	}
}

.contact-form-wrap {
	padding:10*$base-size 0;
}

#loginform {
	padding-bottom: 10*$base-size;

	.login-remember {
		display: none;
	}
	
	label {
		@extend .form-lables;
		display: block;
	}

	input[type=text], input[type=password] {
		height: 5.625rem;
	    padding-left: 1rem;
	    margin-bottom: 0.5rem;
	    width: col-width(12,$gutter,$container,4);
	}

	input[type=submit] {
		@extend .rounded-btn;
	}
}

.crop-links {
	.icon {
		display: none !important;
	}
}

.big-boxes .box:nth-child(2),
.crop_links {
	.icon {
		display: none !important;
	}

}

.big-boxes .box:nth-child(3) {
	@extend .color-block-question;

	.tag {
		color:darken($yellow,20%);
	}

	.heading {
		font-size: 2.8rem;
	}

	.blurb {
		display: none;
	}
}

.blurb {
	margin-top: -1rem;
}

.members {
	clear: both;

	.text-row-heading {
		margin-bottom: 4rem;
		clear: both;
		width: 100%;
	}
}

.members-list {
	margin-bottom: 12*$base-size;
	overflow: hidden;

	li {
		width: col-width(12,$gutter,$container,12);

		@media only screen  and (min-width : $breakpoint-tablet) {
     		width: col-width(12,$gutter,$container,5);
  		}

  		@media only screen  and (min-width : $breakpoint-desktop-medium) {
     		width: col-width(12,$gutter,$container,3);
  		}

		display: inline-block;
		height: 20*$base-size;
		float: left;

		&.no-img {
			height: 6*$base-size;
		}

		a {
			display: block;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			border: 1px solid $line-color;
			margin-right: 0.5*$base-size;
			margin-bottom: 0.5*$base-size;

		}


		img {
			width:200px;
			height: auto;
		}
	}
}


.content-row {
	position: relative;
}

.relative {
	position: relative;
}

.addtoany_share_save_container {
	position: absolute;
	right: $gutter;
	bottom: $gutter;

	.infographic & {
		bottom: 3*$base-size;
		right: -2*$base-size;
	}

    @media only screen  and (max-width : $breakpoint-mobile-end) {
    	display: none;
		right: 0;
		bottom: -1*$gutter;
		transform: scale(0.7);
		
		.page-template-page-resources & {
			bottom: -2*$base-size;
		}
	}

	@media only screen  and (max-width : $breakpoint-tablet) {
		.page-template-page-media & {
			bottom: -1*$base-size;
		}
	}

	

	.related-info-box & {
		display: none;
	}

	.single-question & {
		padding-top: 80px;
		bottom: auto;
		top: $gutter;
		right: col-width(12,$gutter,$container,-1);

		.addtoany_share_save {
			position: absolute;
			top: 0;
			left: -10px;
		}

		@media only screen  and (max-width : $breakpoint-mobile-end) {
			top:auto;
			right:auto;
			left:0;
			bottom:-10*$base-size;

		}

	}

	.tax-question-category & {
		.addtoany_share_save {
			background:url('../../img/icon-share.svg') center center no-repeat;
			text-decoration: none;

			&:after {
				content:'Share';
				font-size: 12px;
				text-transform: uppercase;
				display: block;
				text-align: center;
				text-decoration: none;
			}

			img {
				visibility: hidden;
			}
		}
	}

	.a2a_button_facebook,
	.a2a_button_twitter,
	.a2a_button_linkedin {
		margin-top: -4px;
		span {
			visibility:hidden;
		}
	}

	.a2a_button_facebook {
		background:url('../../img/icon-facebook-grey.svg') center center no-repeat;
	}

	.a2a_button_twitter {
		background:url('../../img/icon-twitter-grey.svg') center center no-repeat;
	}

	.a2a_button_linkedin {
		background:url('../../img/icon-linkedin-grey.svg') center center no-repeat;
	}

	.a2a_button_facebook,
	.a2a_button_twitter,
	.a2a_button_linkedin {
		display: none;

		.single-question & {
			display: block;
		}

	}
}

.icon-box {
	position: absolute;
	left: 0;
	bottom: -4.6875*$base-size;

	.icon {
		width:9.375*$base-size;
		height:9.375*$base-size;
		position: relative;

		img {
			max-width: 100%;
		}
	}

	.text {
		position: absolute;
	}
}

.color-block-question .tiny-headline {
	color: transparent;
}

.box.horizontal-box .icon img {
	width: 50%;
}

.box.horizontal-box .icon {
	width:6.125*$base-size;
	height:6.125*$base-size;
	background-color: $light-green;
	position: absolute;
	left: -8.5*$base-size;
	top: 2*$base-size;
}

.industry-news .box.vertical-box  .icon {
	background-color: $light-green;
}

.color-scheme-light-green .box.vertical-box  .icon  {
	background-color: darken($light-green,8%);
}
.color-scheme-light-green .box.horizontal-box  .icon  {
	background-color: darken($light-green,8%);
}

body.mobile-nav-active {
  overflow: hidden;
}

#mobile-header {
	position: relative;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 1000;
	display: none;
	height: 15*$base-size;
	height: 11*$base-size;
	background-color: white;
	border-bottom: 1px solid $line-color;

  	.has-top-image & {
		background-color: transparent;
		border-bottom: 1px solid rgba(0,0,0,0.08);
		position: absolute;
	}

	.tax-question-category.has-top-image & {
		border-bottom: 1px solid $line-color;
		position: relative;
	}

	.mobile-nav-active & {
      border-bottom: none;
    }


	@media screen and (max-width: $breakpoint-tablet) {
	  	display: block;

	  	.has-top-image & {
			display: block;
	  	}

	}
  
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    z-index: 1000;
    pointer-events: none;
    
    .mobile-nav-active & {
      display: none;
    }
  }

  .logo-box-mobile {
  	position: absolute;
  	top:4*$base-size;
  	top:0;
  	left:0;
  	bottom:0;
  	padding-right: 0.5*$base-size;
  	padding-top: 0.25*$base-size;
  	
  	.mobile-nav-active & {
      display: none;
    }

	.has-top-image & {
		background-color: $dark-green;
	}
  }

  .mobile-logo {
	width: 18*$base-size;
	height: 5.5*$base-size;
	padding-top: 2*$base-size;
	padding-bottom: 2*$base-size;
	background: url('../../img/logo_v1.svg') no-repeat;
	background-size: contain;
	margin-left: $gutter;
	margin-top:2.5*$base-size;
	display: block;

	.has-top-image & {
		background:url('../../img/logo_reversed.svg') no-repeat;
		background-size: contain;
	}

	.mobile-nav-active & {

	}
  }

  .topbar {
  	height:4*$base-size;
  	display: none;
  	background-color:$dark-green;

  	.members {
  		background-color: darken($dark-green,8%);
  		text-decoration: none;
  		line-height: 4*$base-size;
  		padding: 0 2*$base-size;
  		color: white;
  		//height:4*$base-size;
  		display: inline-block;
  		float: right;
  		margin-right:2*$base-size;
  	}
  }
  
  .menu-toggle {
    position: absolute;
    right:$gutter;
    top:5.5*$base-size;
    top:2.5*$base-size;
    cursor: pointer;
    display: inline-block;
    width: 90px;
    height: 6.125*$base-size;
    pointer-events: all;
    z-index: 1001;
   	background:url('../../img/menu-closed.svg') top right no-repeat;
   	background-size: contain;
     
	.mobile-nav-active & {
      	background:url('../../img/menu-opened.svg') top right no-repeat;
      	background-size: contain;
     }


    .label {
      position: absolute;
      right: 8*$base-size;
      top: 2.6*$base-size;
      transition: opacity 0.2s;
      color: $dark-green;
      font-size: 1.75*$base-size;
      letter-spacing: 0;
      
      &.closed {
        opacity: 1;
      }
      
      &.open {
        opacity: 0;
      }
      
      body.mobile-nav-active & {
        &.closed {
          opacity: 0;
        }
        &.open {
          opacity: 1;
        }
      }
    }


    
    .toggle {
      position: absolute;
      top: 13px;
      right: 0px;

      
 
      
      body.mobile-nav-active & {
        &:before, &:after {
          top: 0px;
        }
      }
    }
  }
}

#mobile-menu {
  display: none;
  display: block !important;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: white;
  overflow: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 70;
  padding-top: 12*$base-size;
  
  @media only screen  and (max-width : $breakpoint-tablet) {
    display: block;
  }
  
  body.mobile-nav-active & {
    pointer-events: all;
    opacity: 1;
  }
  
  .menu-content {
    position: relative;
    transition: top 0.5s;
    margin-top: -5px;
  }
  
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    
    li {
        border-bottom: 1px solid $line-color;

        li:nth-child(1) {
        	display: none;
        }
        .child a {
        	background:white !important;
        	padding: 2rem 3rem 2rem 9rem;
        	display: inline-block;
        }
      
      a, .section-title {
        color: inherit;
        padding: 3*$base-size $base-size*3 3*$base-size $base-size*9;
        text-decoration: none;
        display: block;

		span {
			font-weight: 400;
			font-family: $sans-serif-medium;

			&.bold {
				font-weight: 400;
				font-family: $sans-serif-light;
			}
		}


      }
    }
  }
  
  .primary {
  
    .root {
      margin: 0px;
      padding: 0px;
      list-style: none;

      @media only screen  and (min-width : $breakpoint-tablet) {
	 	font-size: $base-size*3 !important;
	  }

      //Plant science
      li:nth-child(1) {
      		background:url('../../img/icon-plant-science-round-green.svg') 4*$base-size 2.2*$base-size no-repeat;
			background-size: 3*$base-size 4.5*$base-size;
      }
      //Health & safety
      li:nth-child(2) a {
			background:url('../../img/icon-health-round-orange.svg') 4*$base-size 1.8*$base-size no-repeat;
			background-size: 3*$base-size 4.5*$base-size;
      }
      //Deolivering food
      li:nth-child(3) a {
			background:url('../../img/icon-feeding-round-yellow.svg') 4*$base-size 1.8*$base-size no-repeat;
			background-size: 3*$base-size 4.5*$base-size;
      }
      //Supporting farmers
      li:nth-child(5) a {
			background:url('../../img/icon-supporting-round-blue.svg') 4*$base-size 1.8*$base-size no-repeat;
			background-size: 3*$base-size 4.5*$base-size;
      }
      //protecting envirinment
      li:nth-child(4) a {
			background:url('../../img/icon-environmental-round-green.svg') 4*$base-size 1.8*$base-size no-repeat;
			background-size: 3*$base-size 4.5*$base-size;
      }

      li:nth-child(1) {
      	border-top: none;
      }

      li:nth-child(10) {
      	border-bottom: none;
      }
      
      .section {
        margin-bottom: $base-size*2;
        
        .section-title {
          font-size: $base-size*3;
          line-height: 1em;
          font-weight: 500;
        }
        ul {
        	height: auto !important;
        }
      }
    }
  }
  
  ul {
    li {
      ul {
        padding: 0px;
        margin: 0px;
        display: none;
        overflow: hidden;
        
        li {

        }
      }
      
      .section-title {
        transition: color 0.3s;
        cursor: pointer;
      }
      
      a.active {
        color: red;
      }
    }
  }
  
  .secondary {
    margin-top: $base-size*2;
    background-color: $light-green;
    padding-top: $base-size*3;
    padding-bottom: $base-size*4;
    bottom: -2*$base-size;
    position: relative;

    li {
    	border-bottom: 0;
    	list-style-type: none;
    }

    .section, a {
    	padding:$base-size $base-size*4;
    	color: $dark-green;
    }

    ul {
    	height: auto !important;

    	.child {
    		a {
    			padding:$base-size*1 $base-size;
    			color: $dark-green;

    		}
    	}

    }

    li a {
    	color: $darkest-green;
    	
    }
  }
}

.hardcodedtable table { margin: 20px 0; background-color:#fff; }
.hardcodedtable table { }
.hardcodedtable table td { border:1px solid #ccc;   }
.hardcodedtable table { background-color:#fff; margin:20px 0}
.hardcodedtable
.hardcodedtable th {background-color:#018DB1;font-weight:bold;}
.hardcodedtable tbody td{ }
.hardcodedtable td {  }
.hardcodedtable tbody {width:100%;}
.hardcodedtable table {width:100%;}

.dataTables_wrapper {
  overflow: scroll;
	  @media only screen  and (min-width : $breakpoint-tablet) {
	    width: 160%;
		margin-left: -4em;

        .dataTables_length ,
        .dataTables_info {
          padding-left: 8rem;
        }
	  }
	  @media only screen  and (min-width : $breakpoint-desktop) {
	    width: calc(100% + 4em);
		margin-left: -4em;
	  }
}

.hidden-tablet {
    display: none;
  @media only screen  and (min-width : $breakpoint-desktop) {
    display: block;
  }
}


.hardcodedtable,
.tablepress {
	background-color: $light-green;
	color: $dark-green;
	padding: $gutter;
	margin: $gutter 0;

	  @media only screen  and (min-width : $breakpoint-tablet) {
	    width: 160%;
		margin-left: -4em;
	  }
	  @media only screen  and (min-width : $breakpoint-desktop) {
	    width: calc(100% + 4em);
		margin-left: -4em;
	  }
	
	tfoot th,
	thead th {
		background-color: $light-green !important;
		padding: 2*$base-size 2.5*$base-size;
	}

	thead th {
		border-bottom: none !important;
	}


	@extend .body-copy;

	tr {
		border:none !important;
		border-top: 1px solid rgba(1,75,38,0.06) !important;
		border-top: none !important;
		width:100%;
		
		&:nth-child(1) {
			border-top: none;
		}
	}

	tbody tr td:nth-of-type(1),
	.column-1 {
		font-family:$sans-serif-medium;
		font-weight: normal;
        @media only screen  and (min-width : $breakpoint-desktop) {
		padding-left: 8*$base-size;
        }
	}
	tbody tr td:nth-last-of-type(1) {
		padding-right: 8*$base-size;
	}

	tbody tr:nth-last-of-type(1) td {
		padding-bottom: 6*$base-size;
	}

	tbody tr:nth-first-of-type(1) td {
		padding-top: 6*$base-size;
	}

	tbody td {
		padding: 2*$base-size 2.5*$base-size;
		background-color: $light-green;
		border:none !important;

		span {
			@extend .body-copy;
			font-family:$sans-serif-light !important;

		}
	}

	 tbody tr:nth-child(odd) {
	 	background-color: lighten($light-green,3%);

	 	td {
	 		background-color: lighten($light-green,3%);
	 	}
	 }

	 p {
	 	text-align: left;
	 }
}

div.post-content {
  line-height:20px;
 }

 .press-kit {
 	h3 {
 		color: $darkest-green;
 		font-family: $sans-serif-medium;
 	}

 	.icon{
 		background-color: transparent !important;

 		img {
	 		width: 25%;
	 		height: auto;
	 	}
 	}

 }

#mct-ai-attriblink a {
	margin-top: 3*$base-size;
	margin-bottom: 13*$base-size;
	float: left;
	@extend .rounded-btn;

	&.mct-ai-link {
		background-color: transparent;
	}
}

.footer .col-5 {
	overflow: hidden;
}

.fitframe-wrap {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.fitframe-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%!important;
  height: 100%!important;
}

.inline-menu {
	li {
		list-style-type: none;
		display: block;
		border-bottom: 1px solid $line-color;
		padding:1*$base-size 0;
		margin-left: 0;

		ul li {
			padding-left:2*$base-size;
		}

		.rich-text & {
			margin-left: 0;
		}

		a {
			text-decoration: none;
		}
	}
}

.related-headline {
	@extend .small-headline;
}

.related-list {
	li {
		padding: 0;
		margin: 0;


		h3 {
			position: relative;
			padding-left: 2*$base-size;

			&:before {
		 		position:absolute;
		 		width:1*$base-size;
		 		height:1*$base-size;
		 		top: 1*$base-size;
		 		background:url('../../img/icon-arrow-plain.svg') center center no-repeat;
				background-size: contain;
				left: 0;
				content: ' ';

				@media only screen  and (min-width : $breakpoint-tablet) {
				   	top: 50%;
		 			margin-top: -0.5*$base-size;
				}
		 	}
		}
	}
	a {
	 	text-decoration: none;
	 	
	 	display: block;
	 }
}

.infographic {
	position: relative;
}

.addtoany_list a:hover img,
.addtoany_list a:hover span {
	opacity: 1 !important;
}

.tablepress .row-hover tr:hover td {
	background-color: darken($light-green,5%);
}

.dataTables_filter, .dataTables_length {
  margin-bottom: 10px;
}

.dataTables_wrapper {
  margin-top: 20px;
}

.dataTables_filter {
  input {
    font-size: inherit;
    font-family: inherit;
    padding: 5px 5px;
    -webkit-appearance: none;
    border: 1px solid #bbbbbb;
  }
}