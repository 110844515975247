.header {
	background-color: $white;
	border-bottom: 1px solid rgba(0,0,0,0.08);
	width: 100%;
	left: 0;
	right: 0;
	position: relative;
	display: none;

	@media screen and (min-width: $breakpoint-tablet-landscape) {
	  	display: block;
	  	//background-color: lighten(red,40%) !important;
	}

	@media screen and (min-width: $breakpoint-desktop) {
	  	//background-color: lighten(blue,40%) !important;
	}	

	@media screen and (min-width: $breakpoint-desktop-medium) {
	  	//background-color: lighten(green,60%) !important;
	}

	.has-top-image & {
		background-color: transparent;
		border-bottom: 1px solid rgba(255,255,255,0.2);
		position: absolute;
		display: none;
		
		@media screen and (min-width: $breakpoint-tablet-landscape) {
		  	display: block;
		}

  		.wrapper {
			background: rgba(6,11,74,0.5);
			background: -moz-linear-gradient(top, rgba(6,11,74,0.5) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(6,11,74,0.5)), color-stop(100%, rgba(255,255,255,0)));
			background: -webkit-linear-gradient(top, rgba(6,11,74,0.5) 0%, rgba(255,255,255,0) 100%);
			background: -o-linear-gradient(top, rgba(6,11,74,0.5) 0%, rgba(255,255,255,0) 100%);
			background: -ms-linear-gradient(top, rgba(6,11,74,0.5) 0%, rgba(255,255,255,0) 100%);
			background: linear-gradient(to bottom, rgba(6,11,74,0.5) 0%, rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#060b4a', endColorstr='#ffffff', GradientType=0 );
  		}

		.logo-box {
			background-color: $dark-green;
		}
	}

	.tax-question-category.has-top-image &, .blog.has-top-image & {
		position: relative;
		border-bottom: 1px solid rgba(0,0,0,0.08);

		.wrapper {
			background: none;
		}

		.logo-box {
			background-color: transparent;
		}
	}


	.menu-box {
		position: relative;
		margin-left: $col-2;
		margin-left:col-width(12,$gutter,$container,3);

		@media screen and (min-width: $breakpoint-tablet) {
	  		margin-left:col-width(12,$gutter,$container,2.5);
	  	}		

	  	@media screen and (min-width: $breakpoint-desktop) {
	  		margin-left:col-width(12,$gutter,$container,2.3);
	  	}

		@media screen and (min-width: $breakpoint-desktop-medium) {
	  		margin-left: col-width(12,$gutter,$container,2,2.5);
	  	}
		
		@media screen and (min-width: $breakpoint-desktop-large) {
	  		margin-left: col-width(12,$gutter,$container,2,2);
	  	}

	}


	.topmenu-wrapper {
		width: 100%;
	}

	.top-wrapper {
		background-color: $dark-green;
	}

	.search-btn {
		width: 6*$base-size;
		height: 6*$base-size;
		border-radius: 6*$base-size;
		line-height: 6*$base-size;
		display: block;
		top: 50%;
		margin-top: -1*$base-size;
		right: 3*$base-size;
		position:  absolute;
		background: $dark-green url('../../img/icon-search.svg') center 49% no-repeat;
		background-size: 40%;
	}
}

	.logo-box {
		position: absolute;
		top:4*$base-size;
		bottom:0;
		text-align: center;
		left: -1*$base-size;
		width: col-width(12,$gutter,$container,2,1.5);
		width:28*$base-size;
		padding-right: 6rem;
		display: flex;
	  	align-items: center;
	  	justify-content: center;

	  	@media screen and (min-width: $breakpoint-desktop-medium) {
	  		width: col-width(12,$gutter,$container,2,2);
	  		width:30*$base-size;
	  	}

	}

	.logo {
		
		width: 20*$base-size;
		height: 8*$base-size;
		background:url('../../img/logo_v1.svg') no-repeat;
		background-size: contain;
		position: relative;
		display: inline-block;
		//margin-left: $gutter


		@media only screen  and (min-width : $breakpoint-tablet-landscape) {
			margin-left: 1.8*$gutter;
			margin-top: 1*$gutter;
		}		

		@media only screen  and (min-width : $breakpoint-desktop) {
			width: 20*$base-size;
			height: 7*$base-size;
			margin-left: 1.8*$gutter;
			margin-top:0;
		}		

		@media only screen  and (min-width : $breakpoint-desktop-medium) {
			width: 20*$base-size;
			height: 7*$base-size;
			margin-left: 1.8*$gutter;
		}


		.has-top-image & {
			background:url('../../img/logo_reversed.svg') no-repeat;
			background-size: contain;
		}

		.tax-question-category.has-top-image &, .blog.has-top-image & {
			background:url('../../img/logo_v1.svg') no-repeat;
			background-size: contain;
		}

		a {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		span {
			visibility: hidden;
		}
	}