@mixin baseline(
  $font-size: 14px,
  $baseline: 20px,
  $first-baseline: $baseline,
  $after: 0,
  $ratio: 1.4 // cap height : descender
) {

  $shift: (($ratio / 8) * $font-size);
  $shift: $shift + (($baseline - $font-size) / 2);

  box-sizing: content-box;

  font-size: $font-size;
  line-height: $baseline;
  margin-top: ($first-baseline - $baseline + $shift);
  $aftern: ($after - $shift);
  @if $aftern < 0 {
    margin-bottom: $aftern;
    padding-bottom: 0;
  } @else {
    padding-bottom: $aftern;
    margin-bottom: 0;
  }
}

@function col-width2($n,$extra-gutters: 0) {
  //full grid width = calc(96.3 * (.6em + 1vw) - 0.3rem)
  // 1 column = calc((96.3 * (1vw) - 0.3rem*11)/12)
  // n column = calc((96.3 * (1vw) - 0.3rem*11)/12 * n + .3rem*(n-1))

  @return calc((96.3 * 1vw - 0.3rem*13)/12 * #{$n} + .3rem * #{$n (-1 + $extra-gutters)})

} 



@function col-width($columns,$gutter, $cont: 100vw, $n-cols:1,$extra-gutters: 0) {
 @return calc((#{$cont} - #{$gutter*13})/#{$columns} 
 * #{$n-cols} + (#{$gutter} * #{$n-cols + (-1 + $extra-gutters)}));
}




// defaults
%proto-span {
  box-sizing: border-box;
}

%proto-grid {
}

%proto-row {
}

// grid
@mixin gen-grid($col:12,$gut:30px,$cont:100vw,$namespace:'',$query: 'desktop') {
  $min-width: 0px;
  $max-width: 3000px;

  @if $query == 'desktop' {
    $max-width: 3000px;
    $min-width: 650px;
  }
  @if $query == 'mobile' {
    $max-width: 650px;
    $min-width: 0px;
  }

  @media screen and (min-width:  $min-width) and (max-width: $max-width) {
    .grid {
      
      max-width: $cont;
      width: 100%;
      box-sizing: border-box;
      display: block;
      font-size: 0;
      margin: 0 auto;
      &:after {
        clear: both;
        display:  block;
        content: ' ';
        height: 0;
        visibility: hidden;
      }
      
      %gutter {
      }
      %gut-right {
        margin-right: $gut;
      }
     
      @for $i from 0 through $col {
        @include gen-col($col,$gut,$i,$namespace);
        @include gen-push($col,$gut,$i,$namespace);
      }

    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}


@function gut-size($col, $gut, $inside:$col, $num:1) {
  // $total-gutters: $gut * ($col + 1);
  // @if $col == $inside {
  //   $total-gutters: $gut * ($col + 1);
  // } @else {
  //   $total-gutters: $gut * ($col);
  // }
  $total-gutters: $gut * ($col);
  $total-columns: 38.5rem - $total-gutters;
  $one-gutter: $total-gutters / ($col - 1);
  $one-column: $total-columns / $col;
  $one-col-one-gut: $one-column + $one-gutter;

  $ratio: 100 / (100 - strip-unit($one-col-one-gut) * ($col - $inside));

  @return $one-gutter * $ratio;
}

@function col-size($col,$gut, $inside: $col, $num:1) {
  $subtotal-gutters: ($inside + 1) * $gut;
  $subtotal-columns: 38.5rem - $subtotal-gutters;
  $one-gutter: $subtotal-gutters / ($inside - 1);
  $one-column: $subtotal-columns / $inside;
  $val: (38.5rem - $gut * ($inside - 1))/$col;//* ($inside/$col);//  /$inside*$col * $num;
  $timzah: 1 + ($num / $col);
  @return calc(#{$val} - #{$gut*$timzah});

  @return calc(#{($one-column * $num) + ($one-gutter * ($num - 1))});
}

@mixin gen-col($col,$gut,$num:1,$break:'') {
  .col-#{$break}#{$num} {
    $val:100% / $col * $num + 0%;
    $timzah: 1 + ($num / $col);
    width: calc(#{$val} - #{$gut*$timzah});
    font-size: 1rem;

    // gutter
    margin-left: $gut;

    // prototype
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;

    // off option
    @if $num == 0 {
      display: none;
    }
  }
}

@mixin gen-push($col,$gut,$num:1,$break:'') {
  [class*="col-"].col-#{$break}push-#{$num} {
    $val:100% / $col * $num + 0%;
    $timzah: 1 + ($num / $col);
    margin-left: calc(#{$val} - #{$gut*$timzah} + #{$gut*2});
  }
}
