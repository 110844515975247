.big-bird {
	background: $light-green;
	color: $darkest-green;
	position: relative;
	margin-bottom: col-width(12,$gutter,$container,1.5);
	margin-top: col-width(12,$gutter,$container,3);
	padding-top: 3*$base-size;

	@media only screen  and (min-width : $breakpoint-tablet) {
		margin-left:5*$base-size;
		margin-right:5*$base-size;
		padding-top: 0;
		margin-top: col-width(12,$gutter,$container,1.65);
	}

	&:before {
		width:col-width(12,$gutter,$container,4);
		height:col-width(12,$gutter,$container,4);
		background:url('../../img/icon-twitter-bird.svg') no-repeat;
		background-size: contain;
		display: block;
		content: ' ';
		position: absolute;
		left:col-width(12,$gutter,$container,1.8);
		top: col-width(12,$gutter,$container,-0.5);

		@media only screen  and (min-width : $breakpoint-tablet) {
			width:col-width(12,$gutter,$container,2);
			height:col-width(12,$gutter,$container,2);
		}
	}

	&:after {
		display: block;
		content: ' ';
		position: absolute;
		left:col-width(12,$gutter,$container,1);
		bottom: calc(-1*col-width(12,$gutter,$container,1));
		width: 0;
		height: 0;
		border-style: solid;
		border-width: col-width(12,$gutter,$container,1) col-width(12,$gutter,$container,1) 0 0;
		border-color: $light-green transparent transparent transparent;
	}

	.grid & .col-7 {
		margin-left: 0;
	}

	.social-section {
		overflow: hidden;
	}

	.small-headline {
		padding-left: col-width(12,$gutter,$container,1);
		padding-bottom:2*$base-size;
		margin-bottom:0*$base-size;
		color: $darkest-green;
		margin-left: $gutter;

		@media only screen  and (min-width : $breakpoint-tablet) {
			margin-left:0;
		}

	}

	.tweet-section {
		padding:col-width(12,$gutter,$container,1,1) col-width(12,$gutter,$container,1,1) 5*$base-size col-width(12,$gutter,$container,1,1);

		.tweet {
			p {
				font-size: 3.25*$base-size;
				font-weight: 400;
				color: $darkest-green;
				font-family: $sans-serif-text;
				word-wrap: break-word;

				@media only screen  and (min-width : $breakpoint-tablet) {
					word-break:break-all;
				}
				
			}

			&.littler p {
				font-size: 2.5*$base-size;
				font-weight: 200;
				padding-top: 2*$base-size;
			}
		}

	}


	.twitter-user {
		color: $darkest-green;
		padding-top: 5*$base-size;
	}


}

