.footer {
  padding: 6 * $base-size 0 2 * $base-size 0;
  background-color: $darkest-green;
  color: white;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding: 4 * $base-size 0 2 * $base-size 0;
    margin-top: 4 * $base-size;
  }

  .menu-primary-menu-container {
    @media only screen and (max-width: $breakpoint-tablet) {
      .menu {
        padding-top: 0 !important;
      }
    }

    @extend .primary-menu-links;
    li a,
    li a span {
      color: $white !important;
    }

    li {
      border-left: none !important;
    }
  }

  .social-section .social-list li {
    overflow: visible;

    a {
      padding-left: 0;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .col-2,
    .col-3,
    .col-4,
    .col-5 {
      width: 100% !important;
      float: left;
    }

    .secondary-menu {
      height: auto;
    }

    .col-5 .col-6 {
      width: 40% !important;
      float: left;

      &:nth-child(1) {
        margin-left: 0 !important;
      }
    }

    .menu-primary-menu-container .menu li {
      width: 100%;
    }
  }
}

.bottom-section {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding-top: 3 * $base-size;
  margin-top: 3 * $base-size;

  .fine-print {
    color: white;
    font-size: $base-size * 1.25;
    padding-top: 1 * $base-size;
  }

  .logo-list {
    float: right;
    li {
      display: inline-block;
      margin-left: $base-size * 10;
    }

    .logo {
      height: $base-size * 6;
      width: $base-size * 12;
      display: block;
      margin-left: 0;

      &:nth-child(1) {
        margin-left: 0;
      }

      &.biotech {
        background: url("../../img/logos-biotech.png") no-repeat;
        background-size: contain;
      }

      &.croplife-international {
        background: url("../../img/logos-croplife-international.png") no-repeat;
        background-size: contain;
      }

      &.croplife-asia {
        background: url("../../img/logos-croplife-asia.png") no-repeat;
        background-size: contain;
      }

      &.agsafe {
        background: url("../../img/logos-agsafe.png") no-repeat;
        background-size: contain;
      }
    }
  }
}

.tweet-text {
  color: white;
  font-weight: 300;
  font-family: $zilla;
  font-weight: 500;

  a {
    color: $green;
  }
}

.twitter-user {
  color: white;
  margin-bottom: $base-size * 4;
  font-weight: 500;
  text-decoration: none;
  color: $green;
  display: block;
  margin-top: $base-size * -1;
}

.footer .logo-box {
  position: relative;
  width: auto;
  text-align: left;
  display: block;
  top: 0;

  .logo {
    background: url("../../img/logo_reversed.svg") no-repeat !important;
    background-size: contain !important;
    margin-left: $base-size;

    @media only screen and (max-width: $breakpoint-tablet) {
      height: $base-size * 10;
      width: $base-size * 16;
    }
  }
}
