.mejs-container, .mejs-container .mejs-controls, .mejs-embed, .mejs-embed body {
	background-color: #F6F5EF;

}


.audio-box.single { 
	margin-top: $base-size*6;
}


.mejs-volume-button,
.mejs-controls a.mejs-horizontal-volume-slider,
.mejs-controls .mejs-mute button,
.mejs-volume-button {
	display: none !important;
}

.mejs-controls .mejs-time-rail span, 
.mejs-controls .mejs-time-rail a {
	background-color: #E6E5E0;
}

.mejs-container .mejs-controls div,
.mejs-container * {
	font-family: $sans-serif-light;
}

.mejs-controls .mejs-time-rail .mejs-time-total {
	background-color: darken(#E6E5E0,10%);
}

.mejs-controls .mejs-time-rail .mejs-time-loaded {
	background-color: #E6E5E0;
}

.mejs-controls .mejs-time-rail .mejs-time-current {
	background-color: $dark-green;
}


.mejs-controls .mejs-time-rail span, .mejs-controls .mejs-time-rail a {
	height: 2px;
}

.mejs-controls .mejs-button button,
.mejs-container .mejs-controls .mejs-button {
	width: 3.5*$base-size;
	height: 3.5*$base-size;
	margin:$base-size*0.25 $base-size 0 0;

	.single & {
		margin-top:$base-size*1.5; 
	}

	@media only screen  and (min-width : $breakpoint-tablet) {
		width: 6.25*$base-size;
		height: 6.25*$base-size;
	}
}

.mejs-controls .mejs-button button {
	background:url('../../img/icon-play.svg') center right no-repeat;
	background-size: contain;
}

.mejs-controls .mejs-pause button {
	background:url('../../img/icon-pause.svg') center right no-repeat;
	background-size: contain;
}

.mejs-currenttime-container {
	padding-right:2*$base-size;
}

.mejs-container .mejs-controls .mejs-time {
	padding-right:2*$base-size;
	padding-left:0;

	&.mejs-duration-container {
		padding-left:2*$base-size;
	}
}

.mejs-container .mejs-controls .mejs-time-rail {
	padding-top: 1.5*$base-size;
}

.audio-box,
.audio {
	background-color: #F6F5EF;
	padding:3.5*$base-size 3*$base-size 3*$base-size 3*$base-size !important;

	@media only screen  and (min-width : $breakpoint-tablet) {
		padding:3.5*$base-size 10*$base-size 3*$base-size 13*$base-size !important;
	}

	.media-grid & {
		background-color:transparent;
		padding:0 !important;

		@media only screen  and (min-width : $breakpoint-tablet) {
			padding:0 !important;
		}
	}

	h3 {
		font-size:1.75*$base-size;
		font-family:$sans-serif-medium;
		color: $darkest-green;
		padding-bottom: $base-size;
		line-height: 1.2;
	}

	.tiny-headline {
		color:$dark-green;
		padding-bottom:0.75*$base-size; 
	}
}

.mejs-playpause-button {
	@media only screen  and (min-width : $breakpoint-tablet) {
		position: absolute;
		left: -10*$base-size;
		top: -6*$base-size;
	}
}