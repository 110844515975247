.menu-box {
	//width: col-width(12,$gutter,$container,10);
}

.menu-primary-menu-container .menu {
	float: left;


	.footer & {
		padding-top: 3.4*$base-size;

		.sub-menu { 
			display: none;
		}
	}


	li {
		display: inline-block;
		border-left:1px solid $line-color;
		position: relative;
		width: col-width(12,$gutter,$container,2);
		height:12.5*$base-size;
		position: relative;


		@media only screen  and (min-width : $breakpoint-tablet-landscape) {
			width: col-width(12,$gutter,$container,2.2);
		}


		@media screen and (min-width: $breakpoint-desktop) {
	  		width: col-width(12,$gutter,$container,2.2);
	  	}

		@media screen and (min-width: $breakpoint-desktop-medium) {
	  		width: col-width(12,$gutter,$container,2.1);
	  	}


		.has-top-image & {
			border-left: 1px solid rgba(255,255,255,0.2);
		}

		.tax-question-category.has-top-image &, .blog.has-top-image & {
			border-left: 1px solid $line-color;
		}


		&.menu-plant-science {
			background:url('../../img/icon-plant-science-round-green.svg') 2.5*$base-size 4.5*$base-size no-repeat;
			background-size: 3*$base-size 3*$base-size;
			border-left: none;

			.has-top-image & {
				background:url('../../img/icon-plant-science-round-white.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}

			.tax-question-category.has-top-image & {
				background:url('../../img/icon-plant-science-round-green.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}
		}

		&.menu-ensuring {
			background:url('../../img/icon-health-round-orange.svg') 2.5*$base-size 4.5*$base-size no-repeat;
			background-size:3*$base-size 3*$base-size;

			.has-top-image & {
				background:url('../../img/icon-health-round-white.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}

			.tax-question-category.has-top-image & {
				background:url('../../img/icon-health-round-orange.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}
		}

		&.menu-feeding {
			background:url('../../img/icon-feeding-round-yellow.svg') 2.5*$base-size 4.5*$base-size no-repeat;
			background-size:3*$base-size 3*$base-size;

			.has-top-image & {
				background:url('../../img/icon-feeding-round-white.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}

			.tax-question-category.has-top-image & {
				background:url('../../img/icon-feeding-round-yellow.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}
		}

		&.menu-supporting {
			background:url('../../img/icon-supporting-round-blue.svg') 2.5*$base-size 4.5*$base-size no-repeat;
			background-size:3*$base-size 3*$base-size;

			.has-top-image & {
				background:url('../../img/icon-supporting-round-white.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}

			.tax-question-category.has-top-image & {
				background:url('../../img/icon-supporting-round-blue.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}
		}

		&.menu-protecting {
			background:url('../../img/icon-environmental-round-green.svg') 2.5*$base-size 4.5*$base-size no-repeat;
			background-size:3*$base-size 3*$base-size;

			.has-top-image & {
				background:url('../../img/icon-environmental-round-white.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}

			.tax-question-category.has-top-image & {
				background:url('../../img/icon-environmental-round-green.svg') 2.5*$base-size 4.5*$base-size no-repeat;
				background-size:3*$base-size 3*$base-size;
			}
		}		

		.footer & {
			display: block;
			border-right: none;
			border-left: none;
			background: none !important;
			height: auto;
		}

		&:hover .sub-menu {
			display:block;
			
			.footer & {
				display: none;
			}
		}

		
		a {
			@extend .primary-menu-links;
			
			
			padding-left: 6.75*$base-size;
			padding-left: 7.2*$base-size;
			line-height: 0.8;
			font-size: 1.75*$base-size;
			font-weight: 500;
			line-height: 1.2;
			display: flex;
			justify-content: center;
			flex-direction: column;
			height: 100%;
			width: 100%;

			@media screen and (min-width: $breakpoint-desktop) {
		  		padding-left: 7*$base-size;
		  	}			

		  	@media screen and (min-width: $breakpoint-desktop-medium) {
		  		padding-left: 7.2*$base-size;
		  	}

			.has-top-image & {
				color: white;

				span {
					color: white;
				}
			}

			.tax-question-category.has-top-image &, .blog.has-top-image & {
				color: $text-color;

				span {
					color: $text-color;
				}
			}

			span {
				font-weight: 400;
				font-family: $sans-serif-text;

			}


			span.bold {
				font-size: 1.75*$base-size;
				font-weight: 300;
				display: block;
				font-family: $sans-serif-light;
			}




			.footer & {
				color: white;
				padding-left: 0;
				font-size: 1.75*$base-size;
				padding-top: 0;
				padding-bottom: 1*$base-size;
				display: block;
				height: auto;

				span {
					color: white;
					display: inline-block;
				}
			}
		}

	}


}

.menu-box {
	display: inline-block;
	margin-left: $gutter;
}