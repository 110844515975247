@import url("//hello.myfonts.net/count/34f9b2");

@font-face {
  font-family: "FFMiloWebProMed";
  src: url("../../webfonts/34F9B2_0_0.eot");
  src: url("../../webfonts/34F9B2_0_0.eot?#iefix") format("embedded-opentype"),
    url("../../webfonts/34F9B2_0_0.woff2") format("woff2"),
    url("../../webfonts/34F9B2_0_0.woff") format("woff"),
    url("../../webfonts/34F9B2_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "FFMiloWebProLight";
  src: url("../../webfonts/34F9B2_1_0.eot");
  src: url("../../webfonts/34F9B2_1_0.eot?#iefix") format("embedded-opentype"),
    url("../../webfonts/34F9B2_1_0.woff2") format("woff2"),
    url("../../webfonts/34F9B2_1_0.woff") format("woff"),
    url("../../webfonts/34F9B2_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "FFMiloWebProText";
  src: url("../../webfonts/34F9B2_2_0.eot");
  src: url("../../webfonts/34F9B2_2_0.eot?#iefix") format("embedded-opentype"),
    url("../../webfonts/34F9B2_2_0.woff2") format("woff2"),
    url("../../webfonts/34F9B2_2_0.woff") format("woff"),
    url("../../webfonts/34F9B2_2_0.ttf") format("truetype");
}

$text-color: $grey;
$link-color: $grey;

.body-copy,
.body-copy td,
.body-copy p {
  font-size: 2 * $base-size;
  color: $text-color;
  line-height: 1.3;
  font-weight: 400;
}

.body-copy-large {
  @extend .body-copy;
  font-size: 2 * $base-size;
}

.rich-text,
.rich-text p,
.rich-text p span,
.rich-text .p1 span,
.rich-text .p2 span,
.rich-text .p7 span,
.rich-text .p6 span,
.rich-text .p3 span,
.rich-text li,
.rich-text ol span {
  @extend .body-copy-large;
}

.rich-text ul li {
  list-style-type: disc;
  padding-bottom: 1 * $base-size;
  margin-left: 2 * $base-size;
}
.rich-text ol li {
  list-style-type: decimal;
  padding-bottom: 1 * $base-size;
  margin-left: 2 * $base-size;
}

.rich-text ul {
  padding-bottom: 2 * $base-size;
}

.body-copy-small,
.body-copy-small td,
.body-copy-small p {
  @extend .body-copy;
  font-size: 1.75 * $base-size;
}

.body-copy-small-reverse,
.body-copy-small-reverse p {
  @extend .body-copy-small;
  color: white;
}

.page-heading {
  font-size: 3 * $base-size;
  font-weight: 400;
  font-family: $sans-serif-medium;
  color: $black;
  margin-bottom: calc(3.5 * #{$base-size});
  line-height: 1.2;
  text-decoration: none;

  @media only screen and (min-width: $breakpoint-desktop) {
    font-size: 6 * $base-size;
  }
}

//h2

.heading {
  font-size: $base-size * 2.8;
  color: $black;
  font-weight: 400;
  font-family: $sans-serif-medium;
  margin-bottom: calc(2 * #{$base-size});
  line-height: 1.33;
  text-decoration: none;
  font-family: $zilla;
  font-weight: 500;

  @media only screen and (min-width: $breakpoint-tablet) {
    font-size: $base-size * 2;
  }
}

.page-headline {
  @extend .heading;
  font-size: $base-size * 7;
  font-size: $base-size * 4;
  padding-bottom: $base-size * 5;
  padding-bottom: $base-size * 2;
  margin-bottom: 0;
  line-height: 1.15;
  padding-top: $base-size * 4;

  .single-question & {
    @media only screen and (max-width: $breakpoint-tablet) {
      padding-top: $base-size * 8;
    }
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    font-size: $base-size * 5;
    padding-top: $base-size * 10;
  }

  @media only screen and (min-width: $breakpoint-desktop) {
    font-size: $base-size * 6.4;
    padding-top: $base-size * 12;
  }
}

.medium-headline {
  @extend .heading;
  font-size: $base-size * 3;
  padding-top: $base-size * 2;
  padding-bottom: $base-size * 2;
  margin-bottom: 0;
  line-height: 1.15;

  @media only screen and (min-width: $breakpoint-desktop) {
    font-size: $base-size * 4.5;
  }
}

.large-headline {
  @extend .heading;
  font-size: $base-size * 5.25;
}

.small-headline {
  @extend .heading;
  font-size: $base-size * 2.4;
  padding-top: $base-size * 2;
  padding-bottom: $base-size * 2;
  margin-bottom: 0;

  @media only screen and (min-width: $breakpoint-desktop) {
    font-size: $base-size * 3;
  }
}

.small-headline-reverse {
  @extend .small-headline;
  color: white;
}

.tiny-headline {
  font-size: $base-size * 1.2;
  text-transform: uppercase;
  color: $grey;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-family: $sans-serif-text;
  line-height: 1;

  @media only screen and (min-width: $breakpoint-desktop-medium) {
    font-size: $base-size * 1.5;
  }

  .post-type {
    .question &,
    .page & {
      display: none;
    }
  }

  span {
    font-size: inherit;
    padding-right: $base-size * 0.5;
  }

  .tag {
    display: inline !important;
    font-size: $base-size * 1.2;
    font-family: $sans-serif-light;

    @media only screen and (min-width: $breakpoint-desktop-medium) {
      font-size: $base-size * 1.5;
    }
  }
}

.tag {
  @extend .tiny-headline;
  font-weight: 100;
}

//h3 - h4
.sub-heading-small {
  @extend .heading;
  font-size: $base-size * 2;
  letter-spacing: -0.01em;

  @media only screen and (min-width: $breakpoint-tablet) {
    font-size: $base-size * 1.75;
  }
}

.sub-heading-small-uppercase {
  font-size: $base-size * 1.25;
  text-decoration: uppercase;
}

.sub-heading-small-reverse {
  @extend .sub-heading-small;
  color: $white;
}

.sub-heading-medium {
  @extend .heading;
  font-size: $base-size * 2;

  @media only screen and (min-width: $breakpoint-tablet) {
    font-size: $base-size * 2;
  }
}

h2 {
  @extend .sub-heading-medium;
}

.sub-heading-medium-reverse {
  @extend .sub-heading-medium;
  color: $white;
}

//Menu sizes
.secondary-menu-links {
  font-size: 1.625 * $base-size;
  color: $black;
  text-decoration: none;
}

.primary-menu-links {
  font-size: 2 * $base-size;
  font-weight: 500;
  text-decoration: none;
}

//Caption
.caption {
  font-size: $base-size * 1.75;
  color: $medium-grey;
  font-weight: 400;
}

.caption-reverse {
  font-size: $base-size * 1.25;
  font-weight: 400;
  color: white;
}

//lables
.form-lables {
  @extend .heading;
  font-size: $base-size * 2.5;
  padding-top: $base-size * 2;
  padding-bottom: $base-size * 2;
  margin-bottom: 0;
}

//Filter sizes
.filter-link {
  color: $black;
  font-size: $base-size * 2;
}

.filter-link-large {
  @extend .filter-link;
  font-size: $base-size * 2.75;
}

.preamble,
.preamble p {
  font-weight: 400;
  font-size: $base-size * 2.6;
  line-height: 1.3;
  padding-bottom: 0;
  font-family: $sans-serif-text;

  a {
    font-size: inherit !important;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    font-size: $base-size * 3.25;
  }
}

.preamble {
  margin-bottom: $base-size * 2;

  @media only screen and (min-width: $breakpoint-tablet) {
    margin-bottom: $base-size * 6;
  }

  .single & {
    margin-top: $base-size * 3;
  }
}

strong,
b {
  font-weight: 400;
  font-family: $sans-serif-text;
}

table strong,
b {
  font-weight: 400;
  font-family: $sans-serif-medium;
}

ol li {
  list-style: decimal;
  padding-bottom: $base-size * 2;
}

.text-row-heading {
  font-size: $base-size * 3;
  color: $black;
  font-weight: 400;
  margin-bottom: calc(1 * #{$base-size});
  margin-top: calc(3 * #{$base-size});
  line-height: 1.33;
  font-family: $sans-serif-text;
  text-decoration: none;
  font-family: $zilla;
  font-weight: 500;
}

.social-section,
.contact-section {
  padding-top: $base-size * 4;
  padding-bottom: $base-size * 6;

  @media only screen and (max-width: $breakpoint-tablet) {
    .footer & {
      padding-top: $base-size * 2;
      padding-bottom: $base-size * 2;
    }
  }
}

.footer .secondary-menu {
  padding-top: $base-size * 10 !important;
}

.col-6 .horizontal-box .text {
  width: 40%;

  .tiny-headline,
  .tag {
    //padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }
}

.icon-text {
  font-family: $sans-serif-text;
  font-size: $base-size * 2;
  text-align: left;
  width: 350px;
  position: absolute;
  left: -24.5rem;
  top: 240px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display: none;

  @media only screen and (min-width: $breakpoint-tablet) {
    display: block;
  }

  @media only screen and (min-width: $breakpoint-desktop) {
    left: -5 * $gutter;
    top: 290px;
  }

  @media only screen and (min-width: $breakpoint-desktop-medium) {
    left: -4.1 * $gutter;
  }

  @media only screen and (min-width: $breakpoint-desktop-large) {
    left: -3.5 * $gutter;
  }
}

ol {
  @extend .body-copy;
}

.single-resistance-strategy .rich-text {
  h3,
  div,
  span,
  ol {
    @extend .body-copy-large;
  }

  ol,
  ul,
  ul li {
    margin-left: 0;
  }

  h3 {
    margin: $base-size 0;
  }

  p {
    margin-top: $base-size;
  }
}
