/*Radio btns as checkboxes*/

input[type=radio]{display:none;}

#agents,
.tags {
  display:block;
}

#agents label,
.tags label,
.tags-label {
  padding:0 2*$base-size;
  height: 4*$base-size;
  border-radius: 4*$base-size;
  -webkit-transition: all .3s;
  background-color: $light-green;
  color: $black;
  display: inline-block;
  line-height: 4*$base-size;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.75*$base-size;
  margin-right: 0.75*$base-size;
  margin-top: 0.25*$base-size;


  @media only screen  and (max-width : $breakpoint-tablet) {
    margin-bottom:1*$base-size;
    margin-left:-1*$base-size;
    margin-right:1.5*$base-size;
  }

  &.checked {
    background-color:$dark-green;
  }
}


input[type=radio] + label:hover ,
label.checked {
  color:#fff;
  background-color:$dark-green;
}

input[type=radio] {
  font-size: 2*$base-size;
}

/* General Labels */

.label,
.label span,
.input-label {
  @extend .tiny-headline;
  font-weight: 300;
  color: $medium-grey;
  padding-bottom: 0.5*$base-size;
  letter-spacing: 0.04em;
  display: block;
  line-height: 0.8;

  &.agent {
    padding-left: 1.25*$base-size;
  }
}



/*Labels & Values for Dropdowns*/
.select-box {
  display: inline-block;
  margin-left:2*$base-size;
  margin-right:2*$base-size;
  margin-top: 2*$base-size;
  margin-bottom: 2*$base-size;
  
  .input-label {
    margin-bottom: 8px;
  }

  @media only screen  and (max-width : $breakpoint-tablet) {
    &.media {
          .dropdown {
            max-width: 100%;
          }
      }
    }
      
  

  @media only screen  and (min-width : $breakpoint-tablet) {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0*$base-size;
    margin-bottom: 3*$base-size
  }

  @media only screen  and (min-width : $breakpoint-desktop-medium) {
    margin-bottom: 0*$base-size
  }
}


.dropdown {
  padding: 0;
  font-size: 2.0rem;
  line-height: 1.1;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  outline: none;
  color: $text-color;
  font-family: $sans-serif;
  font-family: $sans-serif-text;
  max-width: 200px;
  background:url('../../img/icon-down-black.svg') center right no-repeat;
  background-size: 14px 14px;
  padding-right: 30px;
}

/*Search*/
.grid .inline-search-box.col-3 {
  margin-left: 0;
}
