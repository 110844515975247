.secondary-menu,  
.primary-menu {	

	.sub-menu {
		display: none;
		position: absolute;
		background-color: white;
		z-index: 10;
		width: col-width(12,$gutter,$container,2.5);
		padding-top: 2.5*$base-size;
		padding-bottom: 4*$base-size;
		margin-top: -0.5*$base-size;
		-webkit-box-shadow: -2px -2px 4px 4px rgba(0,0,0,0.03);
		-moz-box-shadow: -2px -2px 4px 4px rgba(0,0,0,0.03);
		box-shadow: -2px -2px 4px 4px rgba(0,0,0,0.03);
		border:1px solid $line-color;

		.sub-menu {
			display: none !important;
		}

		&:before {
			content:' ';
			position: relative;
			top:-34px;
			left: 30px;
			display:block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 12px 14px 12px;
			border-color: transparent transparent #ffffff transparent;

		}

		li {
			display: block;
			border-right:none;
			height: auto;

			a {
				@extend .secondary-menu-links;
				color: $black;
				font-weight: 400;
				padding-top: 1*$base-size;
			    padding-left: 5.4*$base-size;
			    padding-bottom: 1.5*$base-size;
			    font-family: $sans-serif-text;


				span {
					color: $black;
				}


				.has-top-image & {
					color: $black;

					span {
						color: $black;
					}
				}
			}
		}

	}
}

.secondary-menu .sub-menu {
	margin-top: -0.1*$base-size;

	
}


.secondary-menu li:nth-child(1) .sub-menu {
		margin-left: -3*$base-size;
}