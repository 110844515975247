.teritary-menu {
	display: block;
	background-color: $light-green;
	clear: both;
	overflow: hidden;
}

.pagenav {
	float: left;
	padding-left: 4px;
	text-align: right;
	list-style-type: none;
	width: 100%;

	


	h4 {
		float: left;

		@media only screen  and (max-width : $breakpoint-tablet) {
    	 	padding-right: 2.5*$base-size;
    	 	padding-top: 1.5*$base-size;
	  	}
	}


	li {
		display: inline-block;
		position: relative;

		&:hover .sub-menu {
			display:block;

			.footer & {
				display: none;
			}
		}

		
		.footer & {
			display: block;
		}

		&.menu-members {
			background-color: $darkest-green;
		}
		
		a {
			@extend .secondary-menu-links;
			color: $darkest-green;
			padding-left: 2.5*$base-size;
			padding-right: 2.5*$base-size;
			padding-top: 2.4*$base-size;
			padding-bottom: 2.4*$base-size;
			display: block;
			line-height: 0.8;
			font-size: 1.625*$base-size;
			font-weight: 300;
			position: relative;

			@media only screen  and (max-width : $breakpoint-tablet) {
     			padding-left: 0.5*$base-size;
				padding-right: 0.5*$base-size;
				font-size: 1.5*$base-size;
				padding-top: 2*$base-size;
				padding-bottom: 2*$base-size;
  			}

			.footer & {
				padding-left: 0;
				padding-top: 1*$base-size;
				padding-bottom: 1*$base-size;
			}
		}

		.children {
			display: none;
		}

		&.current_page_item {
			a:after {
				content:' ';
				display:block;
				position: absolute;
				width: 70%;
				left: 15%;
				bottom: 0;
				height: 4px;
				background-color: $dark-green;

				@media only screen  and (max-width : $breakpoint-tablet) {
					height: 2px;
					bottom: 4px;
  				}
			}
		}

	}
}


.menu-headline {
	font-size:$base-size*2;
	font-weight: 400;
	padding: $base-size*1.8 0;
	margin-left: -0.25*$base-size;
	font-family: $sans-serif-text;
	
	a {
		text-decoration:none; 
	}
}