.frontpage-grid {
}

.tagline {
  background: $darkest-green;

  width: 100%;
  text-align: center;
  margin-top: 10 * $base-size;

  @media screen and (min-width: $breakpoint-tablet) {
    height: 16.5 * $base-size;
  }

  .col-10 {
    margin-left: 0 !important;
  }

  a {
    display: block;
    text-decoration: none;
  }

  .link-text {
    color: white;
    display: block;
    padding-bottom: 3 * $base-size;
    padding-top: 2 * $base-size;

    @media screen and (min-width: $breakpoint-tablet) {
      max-width: 550px;
      position: absolute;
      right: $gutter;
      bottom: 3 * $base-size;
      padding-bottom: 0;
      padding-top: 0;
    }

    @media screen and (min-width: $breakpoint-desktop) {
      bottom: $base-size;
    }

    i {
      background: url(../../img/icon-arrow-plain-white.svg) no-repeat bottom
        center;
      background-size: contain;
      width: 1.5 * $base-size;
      height: 1.5 * $base-size;
      display: inline-block;

      @media screen and (min-width: $breakpoint-tablet) {
        width: 3 * $base-size;
        height: 4 * $base-size;
        background-size: auto;
      }
    }
  }

  img {
    max-width: 80%;
    height: auto;
    margin: 6 * $base-size auto 2 * $base-size auto;

    @media screen and (min-width: $breakpoint-tablet) {
      max-width: 550px;
      margin-top: 6 * $base-size;
      margin-left: $base-size;
      margin-bottom: auto;
    }
  }
}

.programs {
  background-color: $light-beige;
  padding: 10 * $base-size 0;
}

.industry-news {
  padding: 5 * $base-size 0;

  @media screen and (max-width: $breakpoint-tablet) {
    .box.vertical-box {
      padding-bottom: 6 * $base-size;
    }
  }

  @media screen and (min-width: $breakpoint-tablet) {
    padding: 10 * $base-size;
  }

  .grid .col-6 {
    @media screen and (max-width: $breakpoint-tablet) {
      width: 90% !important;
    }
  }

  .preamble {
    max-width: 90%;

    @media only screen and (min-width: $breakpoint-mobile-mid) {
      margin-left: 5%;
    }

    @media screen and (min-width: $breakpoint-tablet) {
      max-width: 40%;
      font-size: 2.5rem;
      margin-left: 3.5rem;
    }
  }
}

.program-list {
  margin-right: col-width(12, $gutter, $container, 1);
}

.top-image {
  .color-block a {
    padding: 6.5 * $base-size 4 * $base-size 4 * $base-size 4 * $base-size;
  }

  .home & {
    height: 53.5vw;
    margin-top: 0 !important;

    @media only screen and (max-width: $breakpoint-tablet) {
      height: 90vh;
      margin-top: 0 !important;
    }
  }

  .single.single-issues-campaigns & {
    height: 84vw;
    margin-top: 0 !important;

    @media only screen and (max-width: $breakpoint-tablet-landscape) {
      height: 140rem !important;
      margin-top: 0 !important;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      height: 90vh;
      margin-top: 0 !important;
    }
  }
}

.feature-box {
  position: relative;
  top: 5 * $base-size;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: $breakpoint-tablet) {
    margin-left: 5% !important;
    width: 90%;
  }

  h2 {
    color: #013e21;
  }

  a.mediaLink {
    border-top: 1px solid $line-color;
    padding: 3rem 4rem 3rem 4rem;
    color: $dark-green;
    font-family: $sans-serif-medium;
  }

  .inner-content {
    padding: 6.5rem 4rem 4rem 4rem;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    width: col-width(12, $gutter, $container, 12);
    top: 4 * $base-size;
  }

  @media screen and (min-width: $breakpoint-tablet) {
    width: col-width(12, $gutter, $container, 5);

    &.campaign {
      width: col-width(12, $gutter, $container, 6);
    }
  }

  @media screen and (min-width: $breakpoint-desktop) {
    width: col-width(12, $gutter, $container, 4);

    &.campaign {
      width: col-width(12, $gutter, $container, 6);
    }
  }

  @media screen and (min-width: $breakpoint-desktop-medium) {
    width: col-width(12, $gutter, $container, 4);

    &.campaign {
      width: col-width(12, $gutter, $container, 6);
    }
  }

  @media screen and (min-width: $breakpoint-desktop-large) {
    width: col-width(12, $gutter, $container, 3);

    &.campaign {
      width: col-width(12, $gutter, $container, 6);
    }
  }

  .medium-headline {
    margin-bottom: 0 * $base-size;

    @media only screen and (min-width: $breakpoint-tablet) {
      font-size: 4rem;
    }
  }

  .icon {
    img {
      width: 50%;
    }
  }

  img {
    margin-right: 4px;
  }
}
