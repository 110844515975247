.rounded-btn {
	background-color: $dark-green;
	border-radius:4*$base-size;
	min-width: 13.75*$base-size;
	padding: 2*$base-size 2.5*$base-size;
	line-height: 1.2;
	font-size: 1.75*$base-size;
	color: white !important;
	border:none !important;
	font-family: $sans-serif;
	font-weight: 400;
	text-decoration: none;
	text-align: center;
}