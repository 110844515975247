.page-breadcrumbs {
	display: block;
	padding-top:3*$base-size;
	
	.top-image & {
		@media screen and (max-width: $breakpoint-tablet) {
			position: relative;
			top: 13*$base-size;
		}
	}

	li {
		display: inline-block;
		margin-right: 1*$base-size;
		font-size: 1.5*$base-size;
		

		
		.top-image & {
			color: white;
			margin-top: 13*$base-size;

			@media screen and (max-width: $breakpoint-tablet) {
			  	margin-top: 0;
			  	display: inline;
			}

			a {
				color: white;
			}
		}


		&:nth-child(1) {
			font-family: $sans-serif-text;
		}

		&:before {
			content:'>';
			display: inline-block;
			padding-right: $base-size;
			margin-top: -2px;
		}

		a {
			font-size: 1.5*$base-size;
			text-decoration: none;



		}

		&:nth-child(1) {
			&:before {
				display: none;
			}

			a {
				font-weight: 400;
				text-decoration: none;
			}
		}
	}	

}