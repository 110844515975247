.split {
  -moz-column-count: 2;
  -moz-column-gap: 50px;
  -webkit-column-count: 2;
  -webkit-column-gap: 50px;
  column-count: 2;
  column-gap: 50px; }
  .split span {
    width: 50px;
    margin: 10px; }

#suggestions {
  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  background-color: #eee;
  color: #505050;
  width: 90%;
  padding: 9px 10px 0px 32px;
  opacity: 1; }

#strats li,
#suggestions li,
#search-results li {
  list-style-type: none;
  margin: 0;
  padding: 2*$base-size 0;
}

#search-results li:hover {

}

#strats a, #suggestions a, #search-results a {
  font-size: 2.75*$base-size;
  font-weight: 400;
  text-decoration: none;
  font-family: $zilla;
}

#suggestions:empty {
  opacity: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  height: 0px;
  border-width: 1px;
  border-color: #fff; }

#search-results {
  padding-bottom: 4*$base-size;
}

#strat-search {
  font-size:2*$base-size;
  font:$sans-serif;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  border: none;
  padding: 9px 80px 9px 32px;
  border-radius: 0px;
  height: 7*$base-size;
  background-color: $dark-green;
  color: white;
  border-radius: 6.25*$base-size;
  width: 100%;
  font-weight: 300;
  font-family: $sans-serif;

  @media only screen  and (min-width : $breakpoint-tablet) {
      height: 6.25*$base-size;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
    font-weight: 300;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: white;
    font-weight: 300;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: white;
    font-weight: 300;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: white;
    font-weight: 300;
  }

}

#strat-search:focus {
  background-color:$dark-green;
}

input::-webkit-search-decoration {
  display: none; }

input {
  outline: none; }

.search-group {
  width:100%;
  position: relative;
}

.inputdiv {
  width:auto;
  display:table-cell;
  vertical-align: top;
}

.sbtn {
  width: 80px;
  padding:0;
  position: absolute;
  right: -0.5*$base-size;
  top:2*$base-size;
  display: block;
  height: 3*$base-size;
  background:url('../../img/icon-search.svg') 30% center no-repeat;
  color: transparent;
  background-size: contain;
  padding-top:2*$base-size;

  @media only screen  and (min-width : $breakpoint-tablet) {
    top:0;
    right: 0;
    height: 6.25*$base-size;
    background:url('../../img/icon-search.svg') 60% center no-repeat;
  }
}

.search-trigger {
  border-left:none;
  background-color: transparent;
  width: 80px;
  text-align:center;
  font-size: 13px;
  text-decoration:none;
  display: block;
  height: 4*$base-size;
  cursor: pointer;
  color: transparent;
}

.search-trigger:hover {
  width: 80px;
  text-decoration:none;
}

.filter {
  padding: 0;
  margin: 0;
  line-height: 5px;
  /*width: 550px; */
  /*width: 50%;*/
  font-size: 14px;
  color: #777;
  font-family: $sans-serif-text;
}

.filter > span {
}

.filter #crops, .filter #groups { margin-right: 6%; }
.filter #groups,
.filter #pests,
.filter #crops{
  display:inline-block;
  float:left;
  width: 30%;

  @media only screen  and (max-width : $breakpoint-tablet) {
     width: 25%;
  }
}

/* .filter #crops{ margin-right:10%;} */
.filter > #groups > span,
.filter > #pests > span,
.filter > #crops > span{
  //display: inline-block;
  height:14px;
  //float: left;
  //display:none;
}

.dropdowns select {
  width: 100%;
}



.page img {
  background-blend-mode: multiply;
}

.filter select {
   padding: 0;
   font-size: 2.75*$base-size;
   line-height: 1;
   background-color: transparent;
   border: none;
   -webkit-appearance: none;
   outline: none;
   color: $black;
   font-family: $sans-serif-text;

}

.filter .label {
  display: block;

  .extra {
    display: inline !important;
  }
}

.box-content a {
  clear:both;
  cursor:pointer;
}

.filter-button {
	-webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
	-webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0px;
  padding: 0px;
  border: none;
  text-decoration: none;
  color: #aaa;
}

.filter-button:hover {
	outline: none;
	-webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.filter-button:active {outline: none; text-decoration: none;}
.filter-button:focus {outline: none; text-decoration: none;}
.filter-button:visited {outline: none; text-decoration: none;}
.related-docs {
  padding: 0;
}


.query-boxes {
  margin-bottom: 0;
  display: inline-block;

}
.query-boxes a {
  display: inline-block;
  font-weight: 100;
  cursor: pointer;
  //margin-top: -2*$base-size;
}
.query-boxes a:hover li {
  background-color: rgb(255,190,190);
  color: red;
}

.query-boxes li {
  margin-left: 0 !important;
  margin-left: 10px;
  display: inline-block;
  list-style-type: none !important;
  @extend .tags-label;
  font-weight: 400;


  .thick {
    font-weight: 300;
  }

  .icon-x {
    margin-left: 1.25*$base-size;
    position: relative;
    top: 0.05*$base-size;
    color: $text-color;
  }
}

#search-query-info a {
  clear: none;
  margin-left: 1.5*$base-size;
}

.results-text {
  margin-right: 2.5*$base-size;
}

#search-query-info {
  clear: both;
  font-weight: 500;
  overflow: hidden;
}

.sidebar {
  margin-bottom:$base-size;
  display:block;

  .box-title {
    text-transform:capitalize;
  }
}
.wrapper2 h1 { display:none;}
.box-content img {
  width: 100%;
  margin: 20px 0;
}


@media screen and (max-width: 600px) {
  .table  td {text-align:center; display:table-row;}
}

.thead { font-weight:bold; padding:10px 10px 0 10px; font-size:14px;}
.tbody {border-top:1px solid #ccc; padding:10px;}
tr > .tbody:first-child {font-weight:bold}
tr > .tbody:nth-child(2) {border-top:none;}

.disclaimer {
  color: #888;
}

.filter-menu .row .col-4:first-child {
  margin-left: 0;
}

.no-result {
  float: right;
  position: relative;
  top: $base-size;
}

@media only screen  and (max-width : $breakpoint-tablet) {
  .strat {
    .filter,
    .filter.dropdown {
        display: block;
        width: 100%;
        clear: both;
        margin: 1.5*$base-size 0;
        float: left;
    }

    .filter.dropdowns {
        float: left;
        clear: both;
        padding-top: 2rem;
        margin-top: 3rem;
        margin-left: 2rem;
    }
  }
}
