$black:					#1B1B1B;
$white:					#FFFFFF;
$grey:					#4C4E54;
$medium-grey:			#6A6A6A;
$light-grey:			#E2E2E2;
$light-grey:			#F2F2F2;
$warm-grey:				#F6F5EF;

$darkest-green:			#014B26;
$dark-green:			#00713A;
$green:					#84C156;
$light-green:			#ECF5E4;

//Color Map - Areas
$yellow:				#FDD764;
$dark-yellow:			#FECC0E;
$orange:				#F2863C;
$blue:					#63AAE3;

$light-beige:			#F9F8F4;



//Color Map - Questions
$health-safety:			$orange;
$feeding-the-world:		$yellow;
$enviroment:			$green;
$supporting-farmers:	$blue;

$line-color:$light-grey;
$line-color-green:rgba(0,113,58,0.1);



.color-scheme-light-green {
	background: $light-green;

	a, p, li, span, h2, h1, h3, h4, label {
		color: $darkest-green !important;
	}

	border-color:$line-color-green !important;

	div {
		border-color:$line-color-green !important;
	}

	input, textarea {
		border: 1px solid $light-green !important;
	}
}